export function validatorCpf(cpf: any) {
	cpf = cpf.replace(/\D/g, "");
	
	if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
	
	let result = true;
	
  [9, 10].forEach(function (j) {
    let soma = 0,
      r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach(function (e: any, i: any) {
        soma += parseInt(e) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = false;
	});
	
  return result;
};

export function validatorCnpj(cnpj: any) {
  if (!cnpj) return false

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const validTypes =
    typeof cnpj === 'string' || Number.isInteger(cnpj) || Array.isArray(cnpj)

  // Elimina valor em formato inválido
  if (!validTypes) return false

  // Guarda um array com todos os dígitos do valor
  const match = cnpj.toString().match(/\d/g)
  const numbers = Array.isArray(match) ? match.map(Number) : []

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false
  
  // Elimina inválidos com todos os dígitos iguais
  const items = Array.from(new Set(numbers))
  if (items.length === 1) return false

  // Cálculo validador
  const calc = (x: any) => {
    const slice = numbers.slice(0, x)
    let factor = x - 7
    let sum = 0

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i]
      sum += n * factor--
      if (factor < 2) factor = 9
    }

    const result = 11 - (sum % 11)

    return result > 9 ? 0 : result
  }

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12)
  
  // Valida 1o. dígito verificador
  const digit0 = calc(12)
  if (digit0 !== digits[0]) return false

  // Valida 2o. dígito verificador
  const digit1 = calc(13)
  return digit1 === digits[1]
}
