import { SchedulingRequest } from "../../dtos/scheduling";
import API from "../../services/api";

export const GetScheduling = async (page: number, filter?: any) => {
  try {
    const res = await API.get("/schedule", {
      params: {
        page,
        ...filter
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export const CadScheduling = async (data: SchedulingRequest) => {
  try {
    const res = await API.post("/schedule", data);
    return res;
  } catch (error) {
    throw error;
  }
}

export const UpdateScheduling = async (id: string, data: SchedulingRequest) => {
  try {
    const res = await API.put(`/schedule/${id}`, data);
    return res;
  } catch (error) {
    throw error;
  }
}