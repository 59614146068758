import React, { useEffect, useState } from "react";

import * as yup from "yup";
import { Col, Row } from "antd";

import Input from "../../input";
import { Form } from "../styles";

import Select from "../../../Components/select";

import {
  ComboEmployee,
  CadScheduling,
  GetCEP,
  UpdateScheduling,
} from "../../../requests";

import {
  SchedulingRequest,
  SchedulingResponse,
} from "../../../dtos/scheduling";
import validator from "../../../helpers/validator";
import useSnackBar from "../../../hooks/snackBar";
import { ComboEmployeeProps } from "../../../dtos/combos";
import Modal from "..";
import moment from "moment";

interface ModalProps {
  isOpen: boolean;
  data?: SchedulingResponse | SchedulingRequest;
  getScheduling: () => void;
  setData: (value: any) => void;
  setIsOpen: (value: boolean) => void;
}

export default function ModalScheduling({
  data,
  isOpen,
  setData,
  setIsOpen,
  getScheduling
}: ModalProps) {
  const {
    setIsOpenSnackBar,
    setMessageSnackBar,
    setDescriptionSnackBar,
    setIsSuccess,
  } = useSnackBar();

  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [employees, setEmployees] = useState<ComboEmployeeProps[]>([]);
  const [initialValues, setInitialValues] = useState<
    SchedulingRequest | SchedulingResponse
  >({
    name_client: "",
    address: "",
    number: "",
    zip_code: "",
    technician_id: "",
    scheduling_date: "",
    hour: "",
    obs: "",
  });

  const getCombo = async () => {
    try {
      const { data } = await ComboEmployee();

      setEmployees([
        {
          value: "",
          label: "Selecione",
        },
        ...data,
      ]);
    } catch (error) {}
  };

  const handleChange = (name: string, value: string) => {
    setInitialValues({ ...initialValues, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const schema = {
        name_client: yup.string().required("O campo nome é obrigatório"),
        address: yup.string().required("O campo endereço é obrigatório"),
        number: yup.string().required("O campo numero é obrigatório"),
        zip_code: yup.string().required("O campo cep é obrigatório"),
        technician_id: yup.string().required("O campo técnico é obrigatório"),
        scheduling_date: yup.string().required("O campo data é obrigatório"),
        hour: yup.string().required("O campo estado é obrigatório"),
        obs: yup.string(),
      };

      await validator(initialValues, schema);

      if (initialValues?.id) {
        UpdateScheduling(initialValues.id, initialValues as SchedulingRequest);
      } else {
        await CadScheduling(initialValues as SchedulingRequest);
      }

      setMessageSnackBar("Agendamento");
      setDescriptionSnackBar(
        `${
          initialValues.id ? "Atualização" : "Agendamento"
        } realizado com sucesso!`
      );
      setIsOpenSnackBar(true);
      setIsSuccess(true);

        getScheduling();
        handleCloseModal(false);
    } catch (error) {
      setMessageSnackBar("Cadastro");
      setIsOpenSnackBar(true);

      if (error?.response) {
        setDescriptionSnackBar(error?.response?.data?.message);
      } else {
        setDescriptionSnackBar(`${Object.values(error)[0]}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const getCep = async (cep: string) => {
    setLoadingCep(true);
    try {
      const { data } = await GetCEP(cep);

      setInitialValues({
        ...initialValues,
        address: data.logradouro,
      });
    } catch (error) {
    } finally {
      setLoadingCep(false);
    }
  };

  const handleCloseModal = (value: boolean) => {
    setIsOpen(value);
    setData({});
    setInitialValues({
      name_client: "",
      address: "",
      number: "",
      zip_code: "",
      technician_id: "",
      scheduling_date: "",
      hour: "",
      obs: "",
    });
  };

  useEffect(() => {
    getCombo();
  }, []);

  useEffect(() => {
    if (data?.id) {
      const newDate = data;

      newDate.scheduling_date = moment(newDate.scheduling_date)
        .utc()
        .format("YYYY-MM-DD");
      setInitialValues(data);
    }
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      setIsOpen={handleCloseModal}
      handleOk={handleSubmit}
      title="Agendamento"
      textOk="Salvar"
    >
      <Form>
        <Input
          name="name_client"
          title="Nome"
          onChange={handleChange}
          value={initialValues?.name_client}
        />

        <Row gutter={8}>
          <Col span={8}>
            <Input
              mask="cep"
              title="CEP"
              name="zip_code"
              loading={loadingCep}
              disabled={loadingCep}
              onChange={handleChange}
              value={initialValues?.zip_code}
              onBlur={() => getCep(initialValues.zip_code)}
            />
          </Col>

          <Col span={12}>
            <Input
              name="address"
              title="Endereço"
              loading={loadingCep}
              disabled={loadingCep}
              onChange={handleChange}
              value={initialValues?.address}
            />
          </Col>

          <Col span={4}>
            <Input
              title="Nª"
              name="number"
              onChange={handleChange}
              value={initialValues?.number}
            />
          </Col>
        </Row>

        <Select
          title="Técnico"
          name="technician_id"
          value={initialValues?.technician_id}
          setValue={handleChange}
          dataOption={employees}
        />

        <Row gutter={8}>
          <Col span={12}>
            <Input
              name="scheduling_date"
              title="Data"
              onChange={handleChange}
              type="date"
              value={initialValues?.scheduling_date}
            />
          </Col>

          <Col span={12}>
            <Input
              mask="hour"
              name="hour"
              title="Horario"
              onChange={handleChange}
              value={initialValues?.hour}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
