import { useCallback, useEffect, useMemo, useState } from 'react';

import { Tag } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import { DataContractProps } from '../interface';
import { IGetContractProps } from '../../../requests/contract/interface';
import { formatDate, formatNumberCurrency } from '../../../helpers/mask';
import ButtonIcon from '../../../Components/buttonIcon';
import EyeIcon from '../../../assets/eye.svg';
import { GetContract, ShowContractClient } from '../../../requests';

export const useContract = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const idClient = location?.state?.id_client;

  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [contracts, setContracts] = useState<DataContractProps>();
  const [isOpenModalFilter, setIsOpenModalFilter] = useState(false);

  const statusColor = useMemo(
    () => ({
      ACTIVE: {
        color: '#4CAF50',
        label: 'ATIVO',
      },
      OVERDUE: {
        color: '#FF0000',
        label: 'VENCIDO',
      },
      PENDING: {
        color: '#FFC107',
        label: 'PENDENTE',
      },
      RENOVATED: {
        color: '#2196F3',
        label: 'RENOVADO',
      },
      NOT_RENEWED: {
        color: '#FF9800',
        label: 'NÃO RENOVADO',
      },
    }),
    []
  );

  const colums = [
    {
      name: 'Nome',
      key: 'client',
      sub_key: 'name',
    },
    {
      name: 'Data do serviço',
      render: (data: IGetContractProps) => (
        <span>{formatDate(data.start_date as any)}</span>
      ),
    },
    {
      name: 'Data de vencimento',
      render: (data: IGetContractProps) => (
        <span>{formatDate(data.due_date as any)}</span>
      ),
    },
    {
      name: 'Valor',
      render: (data: IGetContractProps) => (
        <span>{formatNumberCurrency(data.value as number)}</span>
      ),
    },
    {
      name: 'Status',
      render: (data: IGetContractProps) => (
        <span>
          <Tag color={statusColor[data.status].color}>
            {statusColor[data.status].label}
          </Tag>
        </span>
      ),
    },
    {
      name: 'Detalhes',
      render: (data: IGetContractProps) => (
        <ButtonIcon onClick={() => handleSee(data.id)}>
          <img src={EyeIcon} />
        </ButtonIcon>
      ),
    },
  ];

  const getContract = useCallback(
    async (page?: number, status?: string) => {
      setLoading(true);
      try {
        let data: any = [];

        if (idClient) {
          const response = await ShowContractClient(idClient, page);
          data = response.data;
        } else {
          const response = await GetContract(page, { status: filterStatus });
          data = response.data;
        }

        setContracts(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [idClient, filterStatus]
  );

  const handleSee = useCallback((id: string) => {
    navigate('/contrato', { state: { id_contract: id } });
  }, []);

  const handlePagination = useCallback((page?: number) => {
    getContract(page);
  }, []);

  useEffect(() => {
    getContract();
  }, []);

  return {
    colums,
    loading,
    navigate,
    idClient,
    contracts,
    filterStatus,
    isOpenModalFilter,
    getContract,
    setFilterStatus,
    handlePagination,
    setIsOpenModalFilter,
  };
};
