import axios from "axios";

const headers = {
  "Content-Type": "application/json",
};

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});

API.interceptors.request.use(
  async (config: any) => {
    const token = sessionStorage.getItem("accessTokenK");

    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    window.location.href = "/login";
    sessionStorage.removeItem("accessTokenK");
  }

  return Promise.reject(error);
});

export default API;
