import React, { useEffect, useState } from "react";

import { Modal as ModalC } from "antd";

interface ModalProps {
  children: any;
  title: string;
  textOk: string;
  isOpen: boolean;
  loading: boolean;
  handleOk: () => void;
  setIsOpen: (value: boolean) => void;
}

export default function Modal({
  title,
  isOpen,
  textOk,
  loading,
  children,
  handleOk,
  setIsOpen,
}: ModalProps) {
  return (
    <ModalC
      open={isOpen}
      title={title}
      onOk={handleOk}
      okText={textOk}
      cancelText="Fechar"
      confirmLoading={loading}
      onCancel={() => setIsOpen(false)}
    >
      {children}
    </ModalC>
  );
}
