import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";

import Grid from "@mui/material/Grid";

import Menu from "../../Components/menu";
import CardInfo from "../../Components/cardInfo";
import { Content } from "../../Components/content";

import { Container } from "./styles";

import SaleIconDark from "../../assets/saleDark.svg";
import ProfitIconDark from "../../assets/profitDark.svg";
import ExpenditureIconDark from "../../assets/expenditureDark.svg";

import {
  GetDashboardFinancial,
  GetSixMonthPay,
  GetSixMonthSale,
} from "../../requests/dashboard";
import { DashboardDataProps, LastMonths } from "../../dtos/financial";
import { formatCurrency } from "../../helpers/mask";

export default function FinancialDash() {
  const [financialSixMonths, setFinancialSixMonths] = useState<LastMonths[]>(
    []
  );
  const [saleSixMonths, setSaleSixMonths] = useState<LastMonths[]>([]);
  const [dashboardData, setDashboardData] = useState<DashboardDataProps>({
    total_sales: "0",
    total_outlay: "0",
    totalValue: "0",
  });

  const options = (type: "pay" | "sale") => {
    const color = {
      pay: "#7EDD93",
      sale: "#FF2F2F",
    };

    const pay = financialSixMonths?.map((item) => item.date);
    const sales = saleSixMonths?.map((item) => item.date);

    const categories = type === "pay" ? pay : sales;


    return {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories,
      },
      colors: [color[type]],
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return `R$ ${formatCurrency(`${val}`)}`;
        },
        style: {
          fontSize: '12px',
          colors: ["#000"]
        }
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return `R$ ${formatCurrency(`${val}`)}`
          }
        }
      }
    };
  };

  const series = (type: "pay" | "sale") => {
    const pay = financialSixMonths.map((item) => item.value > 0 ? item.value : item.value);
    const sales = saleSixMonths.map((item) => item.value > 0 ? item.value : item.value);
    const data = type === "pay" ? pay : sales;
    
    return [
      {
        name: "Valor",
        data,
      },
    ];
  };

  const getDash = async () => {
    try {
      const [monthPay, monthSale, dash] = await Promise.all([
        GetSixMonthPay(),
        GetSixMonthSale(),
        GetDashboardFinancial(),
      ]);

      setDashboardData(dash);
      setSaleSixMonths(monthSale);
      setFinancialSixMonths(monthPay);
    } catch (error) {}
  };

  useEffect(() => {
    getDash();
  }, []);

  return (
    <Container>
      <Menu />

      <Content>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <CardInfo
              color="#7EDD93"
              icon={SaleIconDark}
              title="Vendas / mês"
              value={JSON?.parse(dashboardData?.total_sales || "0.00")?.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
              link="/vendas"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <CardInfo
              color="#FF2F2F"
              icon={ExpenditureIconDark}
              title="Despesas / mês"
              value={JSON?.parse(dashboardData.total_outlay || "0.00")?.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
              link="/despesas"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <CardInfo
              color="#74C0CF"
              icon={ProfitIconDark}
              title="Capital / mês"
              value={JSON?.parse(dashboardData.totalValue || "0.00")?.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} mt={2}>
            <h3>Vendas últimos 6 meses</h3>
            {/* @ts-ignore */}
            <Chart options={options("pay")} series={series("sale")} type="bar" height={300} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} mt={2}>
            <h3>Despesas últimos 6 meses</h3>
            {/* @ts-ignore */}
            <Chart options={options("sale")} series={series("pay")} type="bar"  height={300} />
          </Grid>
        </Grid>
      </Content>
    </Container>
  );
}
