import styled from "styled-components";

export const Container = styled.button`
  width: 100%;
  height: 150px;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border: none;
  background-color: #fff;
  position: relative;
`
export const Sideline = styled.div`
  position: absolute;
  width: 10px;
  height: 150px;
  background: ${({color}) => color};
  border-radius: 10px 0px 0px 10px;
  top: 0;
  left: 0;
`
export const HeaderCart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
export const IconCard = styled.img``
export const Title = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);
  margin-left: 14px;
`
export const Value = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--blue_1);
  margin-left: 14px;
`