import React, { useEffect, useState } from "react";

import moment from "moment";
import type { Dayjs } from "dayjs";
import { Grid } from "@mui/material";
import { Badge, Calendar, Modal } from "antd";

import locale from "antd/es/date-picker/locale/pt_BR";

import EyeIcon from "../../assets/eye.svg";
import PlusIcon from "../../assets/plus.svg";
import SchedulingIconDark from "../../assets/schedulingDark.svg";

import {
  Container,
  TableTitleContainer,
  ButtonAdd,
  LineButton,
  TitleButton,
  TableContainer,
} from "./styles";

import Menu from "../../Components/menu";
import Table from "../../Components/table";
import { Content } from "../../Components/content";
import ButtonIcon from "../../Components/buttonIcon";
import { GetScheduling } from "../../requests/scheduling";
import ModalScheduling from "../../Components/modal/cadScheduling";

import { SchedulingResponse } from "../../dtos/scheduling";

export default function Scheduling() {
  const date = new Date();
  const today = moment(date);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [dataScheduling, setDataScheduling] = useState<SchedulingResponse[]>(
    []
  );
  const [dataCurrentScheduling, setDataCurrentScheduling] = useState<
    SchedulingResponse[]
  >([]);
  const [currentScheduling, setCurrentScheduling] =
    useState<SchedulingResponse>();
  const [isOpenModalScheduling, setIsOpenModalScheduling] =
    useState<boolean>(false);

  const colums = [
    {
      name: "Nome",
      key: "name_client",
    },
    {
      name: "Endereço",
      key: "address",
    },
    {
      name: "Numero",
      key: "number",
    },
    {
      name: "Ver",
      render: (data: SchedulingResponse) => (
        <ButtonIcon onClick={() => (data.id ? handleSee(data) : {})}>
          <img src={EyeIcon} />
        </ButtonIcon>
      ),
    },
  ];

  const handleSee = (data: SchedulingResponse) => {
    setCurrentScheduling(data);
    setIsOpenModalScheduling(false);
    setIsOpenModal(true);
  };

  const dateCellRender = (value: Dayjs) => {
    const dataCell = dataScheduling?.filter(
      (item) =>
        moment(item.scheduling_date).utc().format("DD/MM/YYYY") ===
        value.format("DD/MM/YYYY")
    );

    return (
      <ul className="events">
        {dataCell.length > 0 && (
          <Badge
            status="success"
            text={`${dataCell.length} Agendamento${
              dataCell.length > 1 ? "s" : ""
            }`}
          />
        )}
      </ul>
    );
  };

  const getScheduling = async () => {
    try {
      const { data } = await GetScheduling(1);

      setDataScheduling(data.data);
    } catch (error) {}
  };

  const handleList = (date: Dayjs) => {
    const dataCurrentScheduling = dataScheduling?.filter(
      (item) =>
        moment(item.scheduling_date).utc().format("DD/MM/YYYY") ===
        date.format("DD/MM/YYYY")
    );

    if (!dataCurrentScheduling.length) return;
    setDataCurrentScheduling(dataCurrentScheduling);
    setIsOpenModalScheduling(true);
  };

  useEffect(() => {
    getScheduling();
  }, []);

  return (
    <Container>
      <Menu />

      <Content>
        <ModalScheduling
          isOpen={isOpenModal}
          data={currentScheduling}
          setIsOpen={setIsOpenModal}
          getScheduling={getScheduling}
          setData={setCurrentScheduling}
        />

        <Modal
          width={900}
          open={isOpenModalScheduling}
          onOk={() => setIsOpenModalScheduling(false)}
          onCancel={() => setIsOpenModalScheduling(false)}
        >
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer>
              <Table
                top={10}
                icon={SchedulingIconDark}
                title="Agenda"
                colums={colums}
                data={dataCurrentScheduling}
              />
            </TableContainer>
          </Grid>
        </Modal>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            <TableTitleContainer>
              <ButtonAdd onClick={() => setIsOpenModal(true)} type="button">
                <LineButton />
                <TitleButton>Novo agendamento</TitleButton>
                <img src={PlusIcon} alt="Plus" />
              </ButtonAdd>
            </TableTitleContainer>

            <Calendar
              onSelect={handleList}
              // @ts-ignore
              cellRender={dateCellRender}
              locale={locale}
            />
          </Grid>
        </Grid>
      </Content>
    </Container>
  );
}
