import moment from "moment"
import { isNumeric } from './utils'

export const cpfMask = (value: any) => {
  if (value) {
    let _value = value.replace(/[^\d]+/g, '')

    if (_value.length <= 11) {
      return _value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    } else {
      return _value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }
  }

  return ""
}

export const rgMask = (value: any) => {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{1})\d+?$/, '$1')
  }

  return ""
}

export const cellMask = (value: any) => {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  }

  return ""
}

export const phoneMask = (value: any) => {
  if (value) {
    return (
      value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
    )
  }

  return ""
}

export const cepMask = (value: any) => {
  if (value) {
    return (
      value
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')
    )
  }

  return ""
}

export const dateMask = (value: any) => {
  if (value) {
    value = JSON.stringify(value)
    return (
      value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(-\d{4})\d+?$/, '$1')
    )
  }

  return ""
}

export const hourMask = (value: any) => {

  if (value) {
    const _value = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1:$2')
      .replace(/(-\d{2})\d+?$/, '$1')

    if(_value.length > 5){
      return _value.substr(-20, 5)
    } 

    return _value
  }

  return ""
}

export const onlyNumber = (value: any) => {
  if (value) {
    return value
      .replace(/\D/g, '')
  }

  return ""
}

export const formatCurrency = (value: any) => {
  if(value){
    value = JSON.stringify(value)
    value = parseInt(value?.replace(/[\D]+/g,''));
    value = value + '';

    if(value.length < 2) {
      value = value?.replace(/([0-9]{2})$/g, "0,0$1");
    } else if(value?.length < 3){
      value = value?.replace(/([0-9]{2})$/g, "0,$1");
    } else {
      value = value?.replace(/([0-9]{2})$/g, ",$1");
    }

    value = value?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    if(value === 'NaN'){
      value = 0
    }

    return value
  }

  return ""
}

export const formatNumberCurrency = (value: number) => {
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
  
  return formattedValue;
}

export const formatCurrencyToNumber = (value: string) => {
  if(isNumeric(value)) return value;
  
  if(value){
    value = value?.replace('.', '')?.replace(',', '.')
    return Number(value);
  };

  return 0;
}

export const formatDate = (value: Date) => {
  if(value){
    return moment(value).utc().format("DD/MM/YYYY");
  };
}