import React, { useState } from 'react'
import Input from '../input'

import Select from '../select'

import {
  Container,
  InputContainer,
  SelectContainer
} from './styles'

interface ISearchProps{
  value: string;
  setValue: (name: string, value: any) => void;
  inputData: string;
  setInputData: (value: any) => void;
  name: string
}

export default function Search({
  value,
  setValue,
  inputData,
  setInputData,
  name
}: ISearchProps) {
  const [label, setLabel] = useState<string>('')


  const dataSelect = [
    {
      value: '',
      label: 'Selecione'
    },
    {
      value: 'name',
      label: 'Nome'
    },
    {
      value: 'cpf_cnpj',
      label: 'CPF/CNPJ'
    },
    {
      value: 'phone',
      label: 'Telefone'
    },
    {
      value: 'cellPhone',
      label: 'Celular'
    }
  ]

  return (
    <Container>
      <SelectContainer>
        <Select
          title="Tipo"
          value={value}
          setValue={setValue}
          dataOption={dataSelect}
          name={name}
        />
      </SelectContainer>

      <InputContainer>
        <Input 
          name='search'
          title={label}
          onChange={(_, value) => setInputData(value)}
          disabled={(value === '')}
        />
      </InputContainer>
    </Container>
  )
}