import React from 'react'
import { LoadingLight } from '../preloaders'

import {
  Container
} from './styles'

import { IButtonProps } from './interface'

export default function Button({
  title, 
  loading, 
  light,
  buttonClick
}: IButtonProps){
  return(
    <Container
      onClick={() => buttonClick()}
      disabled={loading}
      type="button"
      light={light}
    >
      {
        loading ? <LoadingLight/> : title 
      }
    </Container>
  )
}