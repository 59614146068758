import React, { useCallback, useState } from "react";

import { Form } from "../styles";

import Select from "../../../Components/select";
import Modal from "..";

import selectData from '../../../page/cadContract/selectData.json';


interface ModalProps {
  status: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setStatus: (value: string) => void;
  handleSubmit: (page: number, status: string) => void;
}

export default function ModalFilterContract({
  status,
  isOpen,
  setIsOpen,
  setStatus,
  handleSubmit,
}: ModalProps) {

  const [loading, setLoading] = useState(false);

  const handleCloseModal = (value: boolean) => {
    setIsOpen(value);
  };

  const handleClick = useCallback(() => {
    handleSubmit(1, status);
    handleCloseModal(false);
  }, [status]);

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      setIsOpen={handleCloseModal}
      handleOk={handleClick}
      title="Filtrar por:"
      textOk="Filtrar"
    >
      <Form>
        <Select
          title="Status"
          name="status"
          value={status}
          setValue={(_, value) => setStatus(value)}
          dataOption={[
            {
              label: 'TODOS',
              value: ''
            }, 
            ...selectData.statusContract]
        }
        />
      </Form>
    </Modal>
  );
}
