import styled from 'styled-components'

interface ContainerProps {
  width: number
}

export const Form = styled.form`
  width: 100%;
  row-gap: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`
export const Title = styled.span`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--blue_1);
  display: block;
`
export const InputRowContainer = styled.div`
  display: flex;
  column-gap: 20px;

  @media(max-width: 430px) {
    flex-direction: column;
    row-gap: 30px;
  }
`
export const InputContainer = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${({width}) => width}px;

  @media(max-width: 430px) {
    max-width: none;
  }

`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`