import styled from "styled-components";

interface InputContainerProps {
  width: number
}

export const Container = styled.div`
  display: flex;
`
export const Content = styled.div`
  padding: 60px 100px 60px 300px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;

  @media(max-width: 820px) {
    padding: 60px 50px 60px 250px;
  }

  @media(max-width: 600px) {
    padding: 60px 20px 60px 20px;
  }
`
export const Title = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--blue_1);
  width: 100%;
  text-align: center;
  display: block;
`
export const Form = styled.form`
  width: 100%;
  margin-top: 25px;
`
export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;
  max-width: ${({width}) => width}px;
  margin-top: 25px;

  @media(max-width: 900px) {
    max-width: none;
  }
`
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 37.5px;

  @media(max-width: 900px) {
    flex-direction: column;
  }
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
`
export const DividerContainer = styled.div`
  width: 100%;
  padding: 30px 0;
  position: relative;
  display: flex;
  align-items: center;
  /* background-color: #000; */
  /* justify-content */
`
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--blue_3);
  /* background-color: #000; */
`
export const DividerTitle = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  color: var(--blue_3);
  /* width: 100%; */
  /* text-align: center; */
  margin: 0 20px;
  padding: 0 10px;
  background-color: var(--background);
  position: absolute;
  display: block;
`
