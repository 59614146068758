import API from "../../services/api";
import { ILoginProps, IDataEmployeeProps } from "./interface"

export const LoginEmployee = async (data: ILoginProps) => {
  try {
    const response = await API.post("/sessions", data)
    return response
  } catch (error) {
    throw error;    
  }
}

export const LoginAuthenticated = async () => {
  try {
    const response = await API.get("/employee/authenticated")
    return response
  } catch (error) {
    throw error;    
  }
}

export const GetAllEmployee = async (page?: number) => {
  try {
    const response = await API.get("/employee", {
      params: {
        page
      }
    })
    return response
  } catch (error) {
    throw error;    
  }
}

export const ShowEmployee = async (id: string) => {
  try {
    const response = await API.get(`/employee/${id}`)
    return response
  } catch (error) {
    throw error;    
  }
}

export const ComboEmployee = async () => {
  try {
    const response = await API.get("/employee/combo")
    return response
  } catch (error) {
    throw error;    
  }
}

export const CadEmployees = async (data: IDataEmployeeProps) => {
  try {
    const response = await API.post("/employee", data)
    return response
  } catch (error) {
    throw error;    
  }
}

export const UpdateEmployees = async ( id: string, data: IDataEmployeeProps) => {
  try {
    const response = await API.put(`/employee/${id}`, data)
    return response
  } catch (error) {
    throw error;    
  }
}