import { useState, useEffect } from 'react';
import Menu from '../../Components/menu';

import { Container } from './styles';

import Table from '../../Components/table';
import { Content } from '../../Components/content';

import EmployeeIconDark from '../../assets/employeeDark.svg';
import EyeIcon from '../../assets/eye.svg';

import { useNavigate } from 'react-router-dom';
import ButtonIcon from '../../Components/buttonIcon';

import { GetAllEmployee } from '../../requests';
import { DataEmployeeProps } from './interface';
import { IDataEmployeeProps } from '../../requests/employee/interface';
import { cpfMask } from '../../helpers/mask';

export default function Employee() {
  const navigate = useNavigate();

  const [employees, setEmployees] = useState<DataEmployeeProps>();
  const [loading, setLoading] = useState<boolean>(false);

  const colums = [
    {
      name: 'Nome',
      key: 'name',
    },
    {
      name: 'Endereço',
      key: 'address',
    },
    {
      name: 'Numero',
      key: 'number',
    },
    {
      name: 'CPF',
      key: 'cpf',
      render: (data: IDataEmployeeProps) => <span>{cpfMask(data.cpf)}</span>,
    },
    {
      name: 'Ver',
      render: (data: IDataEmployeeProps) => (
        <ButtonIcon onClick={() => (data.id ? handleSee(data.id) : {})}>
          <img src={EyeIcon} />
        </ButtonIcon>
      ),
    },
  ];

  useEffect(() => {
    getEmployees();
  }, []);

  const handleSee = (id: string) => {
    navigate('/funcionario', { state: { id_employee: id } });
  };

  const getEmployees = async (page?: number) => {
    setLoading(true);
    try {
      const { data } = await GetAllEmployee(page);
      setEmployees(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = (page?: number) => {
    getEmployees(page);
  };

  return (
    <Container>
      <Menu />

      <Content>
        <Table
          top={50}
          icon={EmployeeIconDark}
          title='Funcionarios'
          colums={colums}
          data={employees?.data || []}
          titleButtonAdd='Novo funcionario'
          renderButton={() => navigate('/cadastro/funcionario')}
          pagination={employees?.pagination}
          handlePage={handlePagination}
        />
      </Content>
    </Container>
  );
}
