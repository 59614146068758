import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import Chart from 'react-apexcharts';

import Menu from '../../Components/menu';
import CardInfo from '../../Components/cardInfo';
import { Content } from '../../Components/content';
import ButtonIcon from '../../Components/buttonIcon';

import { Container } from './styles';
import { InventoryProps } from '../inventory/interface';

import EyeIcon from '../../assets/eye.svg';
import ClientIconDark from '../../assets/clientsDark.svg';
import ContractIconDark from '../../assets/contractDark.svg';
import SchedulingIconDark from '../../assets/schedulingDark.svg';

import { GetDashboard } from '../../requests/dashboard';
import { DashboardProps } from '../../requests/dashboard/interface';

export default function Dashboard() {
  const navigate = useNavigate();

  const [data, setData] = useState<DashboardProps>({
    inventory: [],
    total_clients: 0,
    total_contracts: 0,
    total_schedules: 0,
    clients: [],
    contracts: [],
  });

  const getDashboard = async () => {
    try {
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();

      const response = await GetDashboard(month, year);
      setData(response);
    } catch (error) {}
  };

  const handleSee = (id: string) => {
    navigate('/seeInventory', { state: { id_client: id } });
  };

  const options = (type: 'clients' | 'contracts') => {
    const clients = data.clients?.map((item) => item.date);
    const contracts = data.contracts?.map((item) => item.date);
    const categories = type === 'clients' ? clients : contracts;

    const color = {
      clients: [
        '#0000FF',
        '#00FF00',
        '#e0d500',
        '#FF00FF',
        '#00FFFF',
        '#FFA500',
      ],
      contracts: [
        '#800080',
        '#008000',
        '#000080',
        '#800000',
        '#008080',
        '#7EDD93',
      ],
    };

    return {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: categories,
      xaxis: {
        categories,
      },
      colors: color[type],
      dataLabels: {
        enabled: false,
      },
    };
  };

  const series = (type: 'clients' | 'contracts') => {
    const pay = data.clients?.map((item) => item.value);
    const contracts = data.contracts?.map((item) => item.value);
    const _data = type === 'clients' ? pay : contracts;

    return _data;
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <Container>
      <Menu />

      <Content>
        <Row gutter={[16, 16]} wrap>
          <Col xs={24} lg={8}>
            <CardInfo
              color='#74C0CF'
              icon={ClientIconDark}
              title='Clientes / mês'
              value={data.total_clients}
            />
          </Col>

          <Col xs={24} lg={8}>
            <CardInfo
              color='#74C0CF'
              icon={ContractIconDark}
              title='Contratos / mês'
              value={data.total_contracts}
            />
          </Col>

          <Col xs={24} lg={8}>
            <CardInfo
              color='#74C0CF'
              icon={SchedulingIconDark}
              title='Agenda / mês'
              value={data.total_schedules}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]} wrap style={{ marginTop: 50 }}>
          <Col xs={24} lg={12}>
            <h3 style={{ textAlign: 'center' }}>
              Clientes nos últimos 6 meses
            </h3>
            {/* @ts-ignore */}
            <Chart
              type='pie'
              height={300}
              // @ts-ignore
              options={options('clients')}
              series={series('clients')}
            />
          </Col>

          <Col xs={24} lg={12}>
            <h3 style={{ textAlign: 'center' }}>
              Contratos nos últimos 6 meses
            </h3>
            {/* @ts-ignore */}
            <Chart
              type='pie'
              height={300}
              // @ts-ignore
              options={options('contracts')}
              series={series('contracts')}
            />
          </Col>
        </Row>
      </Content>
    </Container>
  );
}
