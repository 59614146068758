import API from "../../services/api";

import {
  ICadClientProps
} from './interface'

export const GetClient = async (type: string, value: string, page?: number) => {
  try {
    const response = await API.get("/client", {
      params: {
        type,
        value,
        page
      }
    })
    return response
  } catch (error) {
    throw error;    
  }
}

export const CadClient = async (data: ICadClientProps) => {
  try {
    const response = await API.post("/client", data)
    return response
  } catch (error) {
    throw error;    
  }
}

export const ShowClient = async (id: string) => {
  try {
    const response = await API.get(`/client/${id}`)
    return response
  } catch (error) {
    throw error;    
  }
}

export const UpdateClient = async (id: string, data: ICadClientProps) => {
  try {
    const response = await API.put(`/client/${id}`, data)
    return response
  } catch (error) {
    throw error;    
  }
}