import styled from "styled-components";

interface inputProps{
  isLast?: boolean
}

export const Container = styled.div`
  display: flex;
`
export const LoginContainer = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 330px;
  background-image: linear-gradient(to bottom, var(--blue_1), var(--blue_2));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 10px 10px 0;

  @media(max-width: 580px) {
    max-width: none;
  }
`
export const Logo = styled.img`
  background-color: #C4C4C4;
  height: 150px;
  width: 250px;
  border-radius: 20px;
  margin-bottom: 40px;
`
export const InputContainer = styled.div<inputProps>`
  position: relative;
  width: 100%;
  max-width: 235px;
  height: 45px;
  margin-bottom: ${({isLast}) => isLast ? 40 : 30}px;
  display: flex;
  align-items: center;
`
export const InputIcon = styled.img`
  position: absolute;
  left: 10px;
`
export const Input = styled.input`
  width: 100%;
  height: 45px;
  background-color: #F1F7F9;
  border-radius: 5px;
  border: none;
  padding-left: 50px;
  font-size: 14px;
`
export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span{
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 50px;

    @media(max-width: 780px) {
      width: 100%;
      max-width: 350px;
      text-align: center;
    }
  }

  @media(max-width: 580px) {
    display: none;
  }
  
`
export const ImgLogin = styled.img`
  @media(max-width: 940px) {
    max-width: 500px;
  }

  @media(max-width: 840px) {
    max-width: 400px;
  }

  @media(max-width: 740px) {
    max-width: 300px;
  }

  @media(max-width: 655px) {
    max-width: 200px;
  }
`