import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import Button from "../../Components/button";
import Input from "../../Components/input";
import Menu from "../../Components/menu";

import {
  Container,
  Title,
  Form,
  ButtonContainer,
} from "./styles";

import { Content } from "../../Components/content";

export default function CadInventory() {
  return (
    <Container>
      <Menu />

      <Content>
        <Title>Cadastro de produtos</Title>

        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Input name="" title="Produto" onChange={() => {}} />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Input name="" title="Fabricante" onChange={() => {}} />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Input name="" title="Quantidade" onChange={() => {}} />
            </Grid>

            <ButtonContainer>
              <Button title="Cadastrar" buttonClick={() => {}} />
            </ButtonContainer>
          </Grid>
        </Form>
      </Content>
    </Container>
  );
}
