import React, { useState } from "react";
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom'

import validator from '../../helpers/validator'
import Button from "../../Components/button";

import {
  Container, 
  LoginContainer, 
  Logo,
  InputContainer,
  InputIcon,
  Input,
  ImgLogin,
  ImageContainer
} from './styles'

import EmailIcon from '../../assets/email.svg'
import PasswordIcon from '../../assets/password.svg'
import LoginImage from '../../assets/loginImage.svg'

import useSnackBar from "../../hooks/snackBar";
import useAuth from "../../hooks/auth";

export default function Login(){
  const navigate = useNavigate()

  const { 
    setIsOpenSnackBar, 
    setMessageSnackBar, 
    setDescriptionSnackBar,
  } = useSnackBar()

  const { signIn } = useAuth()

  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState<boolean>(false)
  

  const handleLogin = async () => {
    setLoading(true)
    try {
      const schema = {
        user: yup.string().required("O campo usuário é obrigatório").email('Informe um email válido'),
        password: yup.string().required("O campo senha é obrigatório")
      }

      await validator({user, password}, schema)
      await signIn({user, password})
      navigate('/dashboard')
    } catch (error: any) {
      setMessageSnackBar('Login')
      setIsOpenSnackBar(true)

      if(error?.response){
        setDescriptionSnackBar(error?.response?.data?.message)
      } else {
        setDescriptionSnackBar(`${Object.values(error)[0]}`)
      }

      setLoading(false)
    }
  }

  return(
    <Container>
      <LoginContainer>
        {/* <Logo src={LogoImage}/> */}

        <InputContainer>
          <InputIcon src={EmailIcon} /> 
          <Input 
            onChange={(e: any) => setUser(e.target.value)}
          />
        </InputContainer>

        <InputContainer
          isLast
        >
          <InputIcon src={PasswordIcon} />
          <Input 
            onChange={(e: any) => setPassword(e.target.value)}
            type="password"
          />
        </InputContainer>

        <Button
          title="Entrar"
          buttonClick={() => handleLogin()}
          loading={loading}
        />
      </LoginContainer>

      <ImageContainer>
        <span>Nunca foi tão facil controlar sua empresa</span>
        <ImgLogin src={LoginImage} />
      </ImageContainer>

    </Container>
  )
}