import styled from "styled-components";

interface typeProps{
  isSuccess?: boolean
}

interface progressProps{
  progress?: number
}

interface containerProps{
  display?: string
}

export const Container = styled.div<containerProps>`
  width: 285px;
  min-height: 100px;
  border-radius: 10px;
  background-color: var(--background);
  display: ${({display}) => display};
  position: fixed;
  top: 45px;
  right: 35px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  z-index: 9999;
`
export const TypeBar = styled.div<typeProps>`
  height: 100%;
  min-height: 100px;
  width: 10px;
  background-color: ${({isSuccess}) => isSuccess ? '#33cc95' : '#e52e4d'};
  border-radius: 10px 0 0 10px;
  z-index: 2;
`
export const ProgressBar = styled.div<progressProps>`
  height: 10px;
  background-color: var(--blue_3);
  width: ${({progress}) => progress}%;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 10px 10px;
`
export const Content = styled.div`
  padding-top: 10px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
`
export const Message = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);
  margin-bottom: 10px;
`
export const Description = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);
  margin-bottom: 10px;
`