import React from 'react'
import { useNavigate } from 'react-router-dom'

import { 
  Container,
  Sideline,
  HeaderCart,
  IconCard,
  Title,
  Value
} from './styles'

interface CardInfoProps {
  color: string
  icon: string
  title: string
  value: number | string
  link?: string
}

export default function CardInfo({
  color,
  icon,
  title,
  value,
  link
}: CardInfoProps){
  
  const navigate = useNavigate()

  return(
    <Container onClick={() => link ? navigate(link) : {}}>
      <Sideline color={color}/>

      <HeaderCart>
        <IconCard src={icon} />
        <Title>{title}</Title>
      </HeaderCart>

      <Value>{value}</Value>
    </Container>
  )
}