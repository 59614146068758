import React from 'react';

import { Col, Row } from 'antd';
import ReactToPrint from 'react-to-print';

import Menu from '../../Components/menu';
import Button from '../../Components/button';
import { Content } from '../../Components/content';

import Logo from '../../assets/images/kazuki-logo.png';

import {
  Title,
  Image,
  TextLine,
  Fieldset,
  Container,
  PDFContainer,
  ContainerInfos,
  ImageContainer,
  ButtonContainer,
  ServicesPerformed,
  TextAuthorization,
  ServicesPerformedTitle,
  Table,
} from './styles';

import { servicesData } from './selectData';

import {
  cepMask,
  cpfMask,
  cellMask,
  phoneMask,
  formatNumberCurrency,
} from '../../helpers/mask';
import { extensiveFormat } from '../../helpers/extensive';
import { usePdfContract } from './hooks/usePdfContract';

export default function PdfContract() {
  const {
    dataPdf,
    componentRef,
    findValueArray,
  } = usePdfContract();

  return (
    <Container>
      <Menu />

      <Content>
        <Title>Imprimir contrato</Title>

        <PDFContainer ref={componentRef}>
          <ImageContainer>
            <Image src={Logo} />
          </ImageContainer>

          <Row gutter={[8, 8]} style={{ zIndex: 1 }}>
            <Col lg={8}>
              <img src={Logo} alt='Logo' style={{ width: 250 }} />
            </Col>
            <Col lg={16}>
              <h3>Controle de Pragas</h3>
              <span>
                Descupinização | Desentupidora | Dedetização | Desratização
              </span>
              <br />
              <span>
                Razão Social: KAZUKI DEDETIDADORA E DESENTUPIDORA LTDA
              </span>
              <br />
              <span>CNPJ: 17.605.930/0001-71</span>
              <br />
              <span>
                Alvará de Funcionamento CEVS. 355060501-812-000011-1-2
              </span>
              <br />
              <span>
                Responsável Técnico: Vanderlan Pedro de Souza – CRQ 04444723
              </span>
              <br />
              <span>Numero do Registro 30605-F</span>
              <br />
            </Col>
          </Row>

          <Row
            style={{
              marginTop: 16,
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
            }}
          >
            <h3>
              CONTRATO DE PRESTAÇÃO DE SERVIÇO{' '}
              <span> N° {dataPdf?.number_contract}</span>
            </h3>
          </Row>

          <Row gutter={[8, 2]} style={{ zIndex: 1 }}>
            <Col lg={24}>
              <ContainerInfos>
                <span>CONTRATANTE:</span>
                <TextLine>{dataPdf?.client.name}</TextLine>
              </ContainerInfos>
            </Col>
            <Col lg={16}>
              <ContainerInfos>
                <span>ENDEREÇO:</span>
                <TextLine>{dataPdf?.client.address}</TextLine>
              </ContainerInfos>
            </Col>
            <Col lg={8}>
              <ContainerInfos>
                <span>BAIRRO:</span>
                <TextLine>{dataPdf?.client.neighborhood}</TextLine>
              </ContainerInfos>
            </Col>

            <Col lg={8}>
              <ContainerInfos>
                <span>CIDADE:</span>
                <TextLine>{dataPdf?.client.city}</TextLine>
              </ContainerInfos>
            </Col>
            <Col lg={8}>
              <ContainerInfos>
                <span>CEP:</span>
                <TextLine>{cepMask(dataPdf?.client.zip_code)}</TextLine>
              </ContainerInfos>
            </Col>
            <Col lg={8}>
              <ContainerInfos>
                <span>FONE:</span>
                <TextLine>
                  {cellMask(dataPdf?.client.cell_phone) ||
                    phoneMask(dataPdf?.client.phone)}
                </TextLine>
              </ContainerInfos>
            </Col>

            <Col lg={24}>
              <ContainerInfos>
                <span>CNPJ/CPF:</span>
                <TextLine>{cpfMask(dataPdf?.client.cpf_cnpj)}</TextLine>
              </ContainerInfos>
            </Col>

            <Col lg={8}>
              <ContainerInfos>
                <span>TÉCNICO RESP:</span>
                <TextLine>{dataPdf?.responsible_technician.name}</TextLine>
              </ContainerInfos>
            </Col>
            <Col lg={8}>
              <ContainerInfos>
                <span>ASSISTENTE:</span>
                <TextLine>{dataPdf?.assistant_technician.name}</TextLine>
              </ContainerInfos>
            </Col>
            <Col lg={8}>
              <ContainerInfos>
                <span>AtençãoSr(a):</span>
                <TextLine>{dataPdf?.client.name}</TextLine>
              </ContainerInfos>
            </Col>

            <Col lg={8}>
              <ContainerInfos>
                <span>COND. PAGTO:</span>
                <TextLine>{dataPdf?.payment}</TextLine>
              </ContainerInfos>
            </Col>
            <Col lg={8}>
              <ContainerInfos>
                <span>REPRES:</span>
                <TextLine></TextLine>
              </ContainerInfos>
            </Col>
          </Row>

          <TextAuthorization>
            AUTORIZO PELO PRESENTE INSTRUMENTO DE SERVIÇOS, EM CONFORMIDADE COM
            ARTIGO 40, PARÁGRAFOS 2° DA LEI 8078/90- CÓDIGO DE DEFESA DO
            CONSUMOR, A EXECUÇÃO DOS SERVIÇOS DA EMPRESA KAZUKI DEDETIZADORA E
            DESENTUPIDORA, ORA DENOMINADA CONTRATADA, ABAIXO RELACIONADOS.
          </TextAuthorization>

          {dataPdf?.services_performed && dataPdf?.services_performed?.length > 0 && (
            <ServicesPerformed>
              <ServicesPerformedTitle>
                SERVIÇOS A SEREM REALIZADOS:
              </ServicesPerformedTitle>
              <br />

              {dataPdf?.services_performed?.map((item, i) => (
                <div key={i}>
                  <strong style={{ color: '#031459' }}>{item.name}: </strong>
                  <span>{item.description}</span>
                  <br />
                </div>
              ))}
            </ServicesPerformed>
          )}

          <Row gutter={[8, 8]}>
            <Fieldset>
              <Col lg={24}>
                <strong>MÉTODOS DE SERVIÇO</strong>
              </Col>
              <Col lg={24}>
                <Row gutter={[8, 8]}>
                  {servicesData.serviceMethod?.map((item, i) => (
                    <Col lg={6} key={i}>
                      ({findValueArray('serviceMethod', item.value) ? 'X' : ' '}
                      ) {item.label}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Fieldset>

            <Fieldset>
              <Col lg={24} style={{ marginTop: 10 }}>
                <strong>PRAGAS ENCONTRADAS</strong>
              </Col>

              <Col lg={24}>
                <Row gutter={[8, 8]}>
                  {servicesData.pestsFound.map((item, i) => (
                    <Col lg={6} key={i}>
                      ({findValueArray('pestsFound', item.value) ? 'X' : ' '}){' '}
                      {item.label}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Fieldset>
          </Row>

          {dataPdf?.characteristics && (
            <Row gutter={[8, 8]} style={{ marginTop: 16, marginBottom: 20 }}>
              <Col lg={24}>
                <strong>
                  CARACTERÍSTICAS DO LOCAL 
                </strong>
              </Col>
              <Col lg={24}>
                  <span>{dataPdf?.characteristics}</span>
              </Col>
            </Row>
          )}

          <Row gutter={[8, 8]} style={{ marginTop: 16, marginBottom: 16 }}>
            <Col lg={12}>
              <strong>DATA: </strong>
              <span>{dataPdf?.start_date}</span>
            </Col>
            <Col lg={12}>
              <strong>GARANTIA: </strong>
              <span>
                {dataPdf?.warrant}{' '}
                {(dataPdf?.warrant as number) > 1 ? 'meses' : 'mês'}
              </span>
            </Col>
          </Row>

          {dataPdf?.services_performed && dataPdf.services_performed?.length > 0 && (
            <>
              <strong>PRODUTOS OU MÁQUINAS UTILIZADOS PARA EXECUÇÃO</strong>

              <Table>
                <thead>
                  <tr>
                    <th>Serviços</th>
                    <th>Discriminação</th>
                    <th>Quant.</th>
                    <th>Valor Unit.</th>
                    <th>Valor Total.</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPdf.services_performed.map((item, i) => (
                    <tr key={i}>
                      <td>{item.name}</td>
                      <td>{item.discrimination}</td>
                      <td>{item.amount}</td>
                      <td>{formatNumberCurrency(item.value)}</td>
                      <td>{formatNumberCurrency(item.total_value)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}

          <Row justify='end'>
            <Col lg={5}>TOTAL GERAL</Col>
            <Col lg={4}>{formatNumberCurrency(dataPdf?.value as any)}</Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col lg={24}>
              <Row>
                <Col lg={7}>VALOR TOTAL POR EXTENSO:</Col>
                <Col
                  lg={17}
                  style={{
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                  }}
                >
                  {extensiveFormat(dataPdf?.value as any)?.toLocaleUpperCase()}
                </Col>
              </Row>
            </Col>

            <Col lg={24}>
              <Row>
                <Col lg={7}></Col>
                <Col
                  lg={17}
                  style={{
                    borderTopWidth: 1,
                    borderTopStyle: 'solid',
                    marginTop: 20,
                  }}
                >
                  CONTRATANTE
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={7}></Col>
            <Col
              lg={17}
              style={{
                fontSize: 8,
              }}
            >
              APROVO ESTA PROPOSTA, FIRMO ACIMA, DANDO POR ACEITE AS CONDIÇÕES
              APRESENTADAS EM VERSOS E INVERSO DO PRESENTE
            </Col>
          </Row>
        </PDFContainer>

        <ReactToPrint
          trigger={() => (
            <ButtonContainer>
              <Button title='Imprimir' buttonClick={() => {}} />
            </ButtonContainer>
          )}
          content={() => componentRef?.current}
        />
      </Content>
    </Container>
  );
}
