import React from 'react'

import {
  Container
} from './styles'

export default function ButtonIcon({
  children,
  onClick
}: any){
  return(
    <Container
      onClick={() => onClick()}
      type="button"
    >
      {children}
    </Container>
  )
}