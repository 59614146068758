import API from "../../services/api";

import {
  ICadContractProps
} from './interface'

export const GetContract = async (page?: number, filter?: any) => {
  try {
    const response = await API.get("/contract", {
      params: {
        page,
        ...filter
      }
    })
    return response
  } catch (error) {
    throw error;    
  }
}

export const ShowContract = async (id: string) => {
  try {
    const response = await API.get(`/contract/show/${id}`)
    return response
  } catch (error) {
    throw error;    
  }
}

export const ShowContractPdf = async (id: string) => {
  try {
    const response = await API.get(`/contract/pdf/${id}`)
    return response
  } catch (error) {
    throw error;    
  }
}

export const ShowContractClient = async (idClient: string, page?: number) => {
  try {
    const response = await API.get(`/contract/client/${idClient}`, {
      params: {
        page
      }
    })
    return response
  } catch (error) {
    throw error;    
  }
}

export const CadContract = async (data: ICadContractProps) => {
  try {
    const response = await API.post("/contract", data)
    return response
  } catch (error) {
    throw error;    
  }
}

export const UpdateContract = async (id: string, data: ICadContractProps) => {
  try {
    const response = await API.put(`/contract/${id}`, data)
    return response
  } catch (error) {
    throw error;    
  }
}