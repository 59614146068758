import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';

import Table from '../../Components/table';
import { Content } from '../../Components/content';
import Menu from '../../Components/menu';
import ButtonIcon from '../../Components/buttonIcon';

import InventoryIconDark from '../../assets/inventoryDark.svg';
import EyeIcon from '../../assets/eye.svg';
import { GetInventory } from '../../requests/inventory';
import { InventoryDataProps, InventoryProps } from './interface';

export default function Inventory() {
  const navigate = useNavigate();

  const colums = [
    {
      name: 'Produto',
      key: 'name',
    },
    {
      name: 'Fabricante',
      key: 'manufacturer',
    },
    {
      name: 'Quantidade',
      key: 'quantity',
    },
    {
      name: 'Ver',
      render: (data: InventoryProps) => (
        <ButtonIcon onClick={() => handleSee(data.id)}>
          <img src={EyeIcon} />
        </ButtonIcon>
      ),
    },
  ];
  const [data, setData] = useState<InventoryDataProps>({
    data: [],
    pagination: {
      current_page: 1,
      total: 0,
      total_pages: 0,
    },
  });

  const getInventory = async (page?: number) => {
    try {
      const { data } = await GetInventory(page);
      setData(data);
    } catch (error) {}
  };

  const cadInventory = () => {
    navigate('/cadastro/estoque');
  };

  const handleSee = (id: string) => {
    navigate('/seeInventory', { state: { id_client: id } });
  };

  const handlePagination = (page?: number) => {
    getInventory(page);
  };

  useEffect(() => {
    getInventory();
  }, []);

  return (
    <Container>
      <Menu />

      <Content>
        <Table
          top={50}
          icon={InventoryIconDark}
          title='Estoque'
          colums={colums}
          data={data.data}
          titleButtonAdd='Novo produto'
          renderButton={cadInventory}
          pagination={data.pagination}
          handlePage={handlePagination}
        />
      </Content>
    </Container>
  );
}
