export const servicesData = {
  "serviceMethod": [
    {
      "value": "Descupinização",
      "label": "Descupinização"
    },
    {
      "value": "Desinsetização",
      "label": "Desinsetização"
    },
    {
      "value": "Desentupimento",
      "label": "Desentupimento"
    },
    {
      "value": "Desratização",
      "label": "Desratização"
    },
    {
      "value": "Limpeza de reservatório",
      "label": "Limpeza de reservatório"
    },
    {
      "value": "Limpeza caixa D`Agua",
      "label": "Limpeza caixa D`Agua"
    },
    {
      "value": "Esgotamento",
      "label": "Esgotamento"
    },
    {
      "value": "Desinfecção",
      "label": "Desinfecção"
    }
  ],

  "pestsFound": [  
    {
      "value": "Cupim subterrâneo",
      "label": "Cupim subterrâneo"
    },
    {
      "value": "Cupim madeira seca",
      "label": "Cupim madeira seca"
    },
    {
      "value": "Brocas",
      "label": "Brocas"
    },
    {
      "value": "Pulgas",
      "label": "Pulgas"
    },
    {
      "value": "Baratas",
      "label": "Baratas"
    },
    {
      "value": "Carrapatos",
      "label": "Carrapatos"
    },
    {
      "value": "Formigas",
      "label": "Formigas"
    },
    {
      "value": "Ratos",
      "label": "Ratos"
    }
  ]
}
