import React, { useState } from "react";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import Menu from "../../Components/menu";
import Input from "../../Components/input";
import Button from "../../Components/button";
import Select from "../../Components/select";
import { Content } from "../../Components/content";

import { Container, Title, Form, ButtonContainer } from "./styles";

import { ExpenditureRequest } from "../../dtos/financial";
import validator from "../../helpers/validator";

import useSnackBar from "../../hooks/snackBar";
import { CadExpenditures } from "../../requests/financial";
import { formatCurrencyToNumber } from '../../helpers/mask';

export default function CadExpenditure() {
  const navigate = useNavigate();

  const {
    setIsOpenSnackBar,
    setMessageSnackBar,
    setDescriptionSnackBar,
    setIsSuccess,
  } = useSnackBar();

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<ExpenditureRequest>({
    category: "",
    situation: "",
    value: undefined,
    due_date: undefined,
    installments: undefined,
  });

  const dataSelect = [
    {
      value: "",
      label: "Selecione",
    },
    {
      value: "paid",
      label: "Pago",
    },
    {
      value: "pending",
      label: "Pendente",
    },
  ];

  const handleChange = (name: string, value: string) => {
    setInitialValues({ ...initialValues, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const schema = {
        category: yup.string().required("Campo Titulo obrigatório"),
        due_date: yup.string().required("Campo Vencimento obrigatório"),
        installments: yup.string().required("Campo Parcelas obrigatório"),
        situation: yup.string().required("Campo Situação obrigatório"),
        value: yup.string().required("Campo Valor obrigatório"),
      };

      await validator(initialValues, schema);

      await CadExpenditures({
        ...initialValues,
        value: formatCurrencyToNumber(initialValues.value as string)
      });

      setInitialValues({
        category: "",
        situation: "",
        value: undefined,
        due_date: undefined,
        installments: undefined,
      });
      setMessageSnackBar("Cadastro");
      setDescriptionSnackBar("Cadastro realizado com sucesso!");
      setIsOpenSnackBar(true);
      setIsSuccess(true);
    } catch (error) {
      setMessageSnackBar("Cadastro");
      setIsOpenSnackBar(true);

      if (error?.response) {
        setDescriptionSnackBar(error?.response?.data?.message);
      } else {
        setDescriptionSnackBar(`${Object.values(error)[0]}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Menu />

      <Content>
        <Title>Cadastro de despesas</Title>

        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name="category"
                title="Titulo"
                onChange={handleChange}
                value={initialValues.category}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Input
                name="due_date"
                title="Vencimento"
                onChange={handleChange}
                value={initialValues.due_date}
                type="date"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Input
                name="value"
                title="Valor"
                mask="currency"
                onChange={handleChange}
                value={initialValues.value}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Select
                name="situation"
                title="Situação"
                value={initialValues.situation}
                setValue={handleChange}
                dataOption={dataSelect}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Input
                type="number"
                title="Parcelas"
                name="installments"
                onChange={handleChange}
                value={initialValues.installments}
              />
            </Grid>
          </Grid>
        </Form>

        <ButtonContainer>
          <Button title="Voltar" buttonClick={() => navigate(-1)} light />
          <Button
            title="Cadastrar"
            buttonClick={() => handleSubmit()}
            loading={loading}
          />
        </ButtonContainer>
      </Content>
    </Container>
  );
}
