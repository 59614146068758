export function extensiveFormat(numero: number): string {
  const unidades = [
    "", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez",
    "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"
  ];

  const dezenas = [
    "", "", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"
  ];

  const centenas = [
    "", "cem", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"
  ];

  if (numero === 0) {
    return "zero reais";
  }

  let parteInteira = Math.floor(numero);
  let parteDecimal = Math.round((numero - parteInteira) * 100);

  let extenso = "";

  // Parte inteira
  if (parteInteira >= 1000) {
    extenso += unidades[Math.floor(parteInteira / 1000)];
    extenso += parteInteira >= 2000 ? " mil" : " mil";
    parteInteira %= 1000;
    extenso += parteInteira > 1 ? ' e ' : ''
  }

  if (parteInteira > 0) {
    if (extenso.length > 0) {
      extenso += " ";
    }
    if (parteInteira < 20) {
      extenso += unidades[parteInteira];
    } else if (parteInteira < 100) {
      extenso += dezenas[Math.floor(parteInteira / 10)];
      if (parteInteira % 10 !== 0) {
        extenso += " e " + unidades[parteInteira % 10];
      }
    } else {

      extenso += centenas[Math.floor(parteInteira / 100)];
      if (parteInteira % 100 !== 0) {
        extenso += " e " + dezenas[Math.floor((parteInteira % 100) / 10)];
        if (parteInteira % 10 !== 0) {
          extenso += " e " + unidades[parteInteira % 10];
        }
      }
    }
    extenso += " reais";
  } else {
    extenso += " reais";
  }

  // Parte decimal
  if (parteDecimal > 0) {
    if (extenso.length > 0) {
      extenso += " e ";
    }
    if (parteDecimal < 20) {
      extenso += unidades[parteDecimal];
    } else if (parteDecimal < 100) {
      extenso += dezenas[Math.floor(parteDecimal / 10)];
      if (parteDecimal % 10 !== 0) {
        extenso += " e " + unidades[parteDecimal % 10];
      }
    } else {
      extenso +=  " e " + centenas[Math.floor(parteDecimal / 100)];
      if (parteDecimal % 100 !== 0) {
        extenso += " e " + dezenas[Math.floor((parteDecimal % 100) / 10)];
        if (parteDecimal % 10 !== 0) {
          extenso += " e " + unidades[parteDecimal % 10];
        }
      }
    }
    if (parteDecimal === 1) {
      extenso += " centavo";
    } else{
      extenso += " centavos";
    }
  }

  return extenso;
}
