import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as yup from 'yup';
import moment from 'moment';
import { Col, Row, Switch, Divider } from 'antd';

import Menu from '../../Components/menu';
import Input from '../../Components/input';
import Table from '../../Components/table';
import Button from '../../Components/button';
import Select from '../../Components/select';
import { Content } from '../../Components/content';
import ButtonIcon from '../../Components/buttonIcon';

import useSnackBar from '../../hooks/snackBar';

import {
  Form,
  Title,
  LineCard,
  Textarea,
  Container,
  TitleCard,
  CardSelected,
  TextareaTitle,
  InputContainer,
  ButtonContainer,
  TextareaContainer,
  DeleteCardSelected,
  RowSelectContainer,
} from './styles';

import TrashIcon from '../../assets/trash.svg';
import TrashDarkIcon from '../../assets/trashDark.svg';

import selectData from './selectData.json';
import { IContractProps, IServicesProps } from './interface';

import {
  CadContract,
  ShowContract,
  ComboEmployee,
  UpdateContract,
  GetCEP,
} from '../../requests';

import validator from '../../helpers/validator';
import {
  cepMask,
  formatCurrencyToNumber,
  formatNumberCurrency,
} from '../../helpers/mask';

import { ComboEmployeeProps } from '../../dtos/combos';
import EditIcon from '../../assets/edit.svg';
import { isNumeric } from '../../helpers/utils';

export default function CadClients() {
  const navigate = useNavigate();

  const location: any = useLocation();
  const { id_client, id_contract } = location?.state;

  const {
    setIsSuccess,
    setIsOpenSnackBar,
    setMessageSnackBar,
    setDescriptionSnackBar,
  } = useSnackBar();

  const [loadingCep, setLoadingCep] = useState(false);
  const [comboEmployees, setComboEmployees] = useState<[]>([]);
  const [initialValues, setInitialValues] = useState<IContractProps>({
    hour: '',
    city: '',
    state: '',
    number: '',
    address: '',
    payment: '',
    warrant: '',
    zip_code: '',
    start_date: '',
    pest_found: [],
    value: undefined,
    neighborhood: '',
    status: 'ACTIVE',
    service_method: [],
    characteristics: '',
    client_id: id_client,
    services_performed: [],
    isAddressDefault: true,
    budget_technician_id: '',
    assistant_technician_id: '',
    value_amount_paid: undefined,
    responsible_technician_id: '',
  });
  const [servicesValues, setServicesValues] = useState<IServicesProps>();
  const [servicesValuesDeleted, setServicesValuesDeleted] = useState<
    IServicesProps[]
  >([]);

  const colums = [
    {
      name: 'Editar',
      render: (data: IServicesProps, index: number) => (
        <ButtonIcon onClick={() => handleEditService(data, index)}>
          <img src={EditIcon} />
        </ButtonIcon>
      ),
    },
    {
      name: 'Excluir',
      render: (data: IServicesProps, index: number) => (
        <ButtonIcon onClick={() => handleDeleteService(data, index)}>
          <img src={TrashDarkIcon} />
        </ButtonIcon>
      ),
    },
    {
      name: 'Serviços',
      key: 'name',
    },
    {
      name: 'Discriminação',
      key: 'discrimination',
    },
    {
      name: 'Quant.',
      key: 'amount',
    },
    {
      name: 'Valor Unit.',
      key: 'value',
      render: (data: IServicesProps) => (
        <span>{formatNumberCurrency(data?.value || 0)}</span>
      ),
    },
    {
      name: 'Valor Total.',
      render: (data: IServicesProps) => (
        <span>{formatNumberCurrency(data?.total_value || 0)}</span>
      ),
    },
  ];

  const getContract = async () => {
    try {
      const { data } = await ShowContract(id_contract);
      const formatData = { ...data };

      formatData.service_method = JSON.parse(formatData.service_method);
      formatData.pest_found = JSON.parse(formatData.pest_found);
      formatData.start_date = moment(formatData?.start_date)
        .utc()
        .format('YYYY-MM-DD');
    
      setInitialValues(formatData);
    } catch (error) {}
  };

  const handleChange = (name: string, value: any) => {
    if (name === 'service_method' || name === 'pest_found') {
      let values: any = [...initialValues[name]];
      values.push(value);

      setInitialValues({ ...initialValues, [name]: values });
    } else {
      setInitialValues({ ...initialValues, [name]: value });
    }
  };

  const handleChangeServices = useCallback(
    (name: string, value: string) => {
      setServicesValues({ ...servicesValues, [name]: value } as IServicesProps);
    },
    [servicesValues]
  );

  const removeSelectArray = (name: string, indice: any) => {
    if (name === 'service_method' || name === 'pest_found') {
      let values: any = [...initialValues[name]];
      values.splice(indice, 1);
      setInitialValues({ ...initialValues, [name]: values });
    }
  };

  const getComboEmployee = async () => {
    try {
      const { data } = await ComboEmployee();

      let newData: any = [
        {
          value: '',
          label: 'Selecione',
        },
      ];

      data?.map((item: ComboEmployeeProps) => {
        newData.push(item);
      });

      setComboEmployees(newData);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      const newData: any = {
        ...initialValues,
        service_method:
          initialValues.service_method.length > 0
            ? JSON.stringify(initialValues.service_method)
            : '',
        pest_found:
          initialValues.pest_found.length > 0
            ? JSON.stringify(initialValues.pest_found)
            : '',
        services_performed: initialValues.services_performed.concat(
          servicesValuesDeleted
        ),
        value: formatCurrencyToNumber(initialValues.value as any),
        value_amount_paid: formatCurrencyToNumber(
          initialValues.value_amount_paid as any
        ),
      };

      const schema = {
        service_method: yup
          .string()
          .required('O campo método de serviço é obrigatório'),
        pest_found: yup
          .string()
          .required('O campo pragas encontradas é obrigatório'),
        characteristics: yup
          .string()
          .required('O campo caracteristicas do local é obrigatório'),
        start_date: yup
          .string()
          .required('O campo data do serviço é obrigatório'),
        warrant: yup.string().required('O campo horario é obrigatório'),
        hour: yup.string().required('O campo horario é obrigatório'),
        budget_technician_id: yup.string(),
        responsible_technician_id: yup
          .string()
          .required('O campo técnico responsavel é obrigatório'),
        assistant_technician_id: yup.string(),
        payment: yup.string().required('O campo pagamento é obrigatório'),
        value: yup.string().required('O campo valor é obrigatório'),
        value_amount_paid: yup
          .string()
          .required('O campo valor pago é obrigatório'),
        isAddressDefault: yup.boolean(),
        address: yup.string().when('isAddressDefault', {
          is: true,
          then: (schema) => schema,
          otherwise: (schema) =>
            schema.required('O campo endereço é obrigatório'),
        }),
        number: yup.string().when('isAddressDefault', {
          is: true,
          then: (schema) => schema,
          otherwise: (schema) =>
            schema.required('O campo número é obrigatório'),
        }),
        zip_code: yup.string().when('isAddressDefault', {
          is: true,
          then: (schema) => schema,
          otherwise: (schema) => schema.required('O campo cep é obrigatório'),
        }),
        neighborhood: yup.string().when('isAddressDefault', {
          is: true,
          then: (schema) => schema,
          otherwise: (schema) =>
            schema.required('O campo bairro é obrigatório'),
        }),
        city: yup.string().when('isAddressDefault', {
          is: true,
          then: (schema) => schema,
          otherwise: (schema) =>
            schema.required('O campo cidade é obrigatório'),
        }),
        state: yup.string().when('isAddressDefault', {
          is: true,
          then: (schema) => schema,
          otherwise: (schema) =>
            schema.required('O campo estado é obrigatório'),
        }),
      };

      await validator(newData, schema);

      if (id_contract) {
        await UpdateContract(id_contract, newData);
      } else {
        await CadContract(newData);
        setInitialValues({
          city: '',
          hour: '',
          state: '',
          number: '',
          warrant: '',
          payment: '',
          address: '',
          zip_code: '',
          pest_found: [],
          start_date: '',
          value: undefined,
          status: 'ACTIVE',
          neighborhood: '',
          service_method: [],
          characteristics: '',
          client_id: id_client,
          isAddressDefault: true,
          services_performed: [],
          budget_technician_id: '',
          assistant_technician_id: '',
          value_amount_paid: undefined,
          responsible_technician_id: '',
        });
      }

      setMessageSnackBar('Cadastro');
      setDescriptionSnackBar('Cadastro realizado com sucesso!');
      setIsOpenSnackBar(true);
      setIsSuccess(true);
    } catch (error) {
      setMessageSnackBar('Cadastro');
      setIsOpenSnackBar(true);

      if (error?.response) {
        setDescriptionSnackBar(error?.response?.data?.message);
      } else {
        setDescriptionSnackBar(`${Object.values(error)[0]}`);
      }
    }
  };

  const handleDeleteService = useCallback(
    (service: IServicesProps, index: number) => {
      const value = [...initialValues.services_performed];

      if (service.id) {
        setServicesValuesDeleted((prevState) => {
          return [
            ...prevState,
            {
              ...service,
              deleted: true,
            },
          ];
        });
      }

      value.splice(index, 1);

      setInitialValues((prevState) => {
        return {
          ...prevState,
          services_performed: value,
        };
      });
    },
    [initialValues]
  );

  const handleEditService = useCallback(
    (service: IServicesProps, index: number) => {
      setServicesValues({ ...service, index: index });
    },
    [servicesValues]
  );

  const handleAddService = useCallback(() => {
    const services = [...initialValues.services_performed];

    if ((servicesValues?.index as number) >= 0) {
      // @ts-ignore
      services[servicesValues?.index] = servicesValues;
    } else {
      services.push({
        ...servicesValues,
        value: formatCurrencyToNumber(servicesValues?.value as any),
        total_value: formatCurrencyToNumber(servicesValues?.total_value as any),
      } as IServicesProps);
    }

    setInitialValues((prevState) => {
      return {
        ...prevState,
        services_performed: services,
      };
    });

    setServicesValues({
      amount: undefined,
      total_value: undefined,
      contract_id: undefined,
      description: '',
      discrimination: '',
      name: '',
      value: undefined,
    });
  }, [servicesValues, initialValues]);

  const getCep = async (cep: string) => {
    setLoadingCep(true);
    try {
      const { data } = await GetCEP(cep);

      setInitialValues((prevState) => ({
        ...prevState,
        address: data.logradouro || initialValues.address,
        city: data.localidade || initialValues.city,
        state: data.uf || initialValues.state,
        neighborhood: data.bairro || initialValues.neighborhood,
      }));
    } catch (error) {
    } finally {
      setLoadingCep(false);
    }
  };

  useEffect(() => {
    getComboEmployee();

    if (id_contract) {
      getContract();
    }
  }, [id_contract]);

  return (
    <Container>
      <Menu />

      <Content>
        <Title>{id_contract ? 'Atualizar' : 'Cadastrar'} contrato</Title>

        <Form>
          <Divider> IFORMAÇÕES DE ENDEREÇO </Divider>

          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <TextareaTitle
                  style={{ position: 'relative', top: 0, left: 0 }}
                >
                  Endereço padrão
                </TextareaTitle>
                <Switch
                  style={{ marginLeft: 8 }}
                  onChange={(value) => handleChange('isAddressDefault', value)}
                  defaultChecked={initialValues.isAddressDefault}
                  checked={initialValues.isAddressDefault}
                />
              </div>
            </Col>

            {!initialValues.isAddressDefault && (
              <>
                <Col span={8}>
                  <Input
                    title='CEP'
                    name='zip_code'
                    loading={loadingCep}
                    disabled={loadingCep}
                    onChange={handleChange}
                    value={cepMask(initialValues.zip_code)}
                    onBlur={() => getCep(initialValues.zip_code)}
                  />
                </Col>

                <Col span={8}>
                  <Input
                    name='address'
                    title='Endereço'
                    loading={loadingCep}
                    disabled={loadingCep}
                    onChange={handleChange}
                    value={initialValues?.address}
                  />
                </Col>

                <Col span={8}>
                  <Input
                    name='number'
                    title='Nª'
                    onChange={handleChange}
                    value={initialValues?.number}
                  />
                </Col>

                <Col span={8}>
                  <Input
                    title='Bairro'
                    name='neighborhood'
                    loading={loadingCep}
                    disabled={loadingCep}
                    onChange={handleChange}
                    value={initialValues?.neighborhood}
                  />
                </Col>

                <Col span={8}>
                  <Input
                    name='city'
                    title='Cidade'
                    loading={loadingCep}
                    disabled={loadingCep}
                    onChange={handleChange}
                    value={initialValues?.city}
                  />
                </Col>

                <Col span={8}>
                  <Input
                    name='state'
                    title='Estado'
                    loading={loadingCep}
                    disabled={loadingCep}
                    onChange={handleChange}
                    value={initialValues?.state}
                  />
                </Col>
              </>
            )}
          </Row>

          <Divider> MÉTODO DE SERVIÇOS </Divider>

          <RowSelectContainer>
            <InputContainer width={240}>
              <Select
                name='service_method'
                title='Método de serviço'
                value={''}
                setValue={handleChange}
                dataOption={selectData.serviceMethod}
              />
            </InputContainer>

            {initialValues?.service_method?.map((item, i) => (
              <CardSelected key={i}>
                <LineCard />
                <TitleCard>{item}</TitleCard>
                <DeleteCardSelected
                  type='button'
                  onClick={() => removeSelectArray('service_method', i)}
                >
                  <img src={TrashIcon} alt='Excluir' />
                </DeleteCardSelected>
              </CardSelected>
            ))}
          </RowSelectContainer>

          <RowSelectContainer>
            <InputContainer width={240}>
              <Select
                name='pest_found'
                title='Pragas encontradas'
                value={''}
                setValue={handleChange}
                dataOption={selectData.pestsFound}
              />
            </InputContainer>

            {initialValues?.pest_found?.map((item, i) => (
              <CardSelected key={i}>
                <LineCard />
                <TitleCard>{item}</TitleCard>
                <DeleteCardSelected
                  type='button'
                  onClick={() => removeSelectArray('pest_found', i)}
                >
                  <img src={TrashIcon} alt='Excluir' />
                </DeleteCardSelected>
              </CardSelected>
            ))}
          </RowSelectContainer>

          <Row gutter={[24, 24]}>
            <Col span={24}>
              <TextareaContainer>
                <TextareaTitle>Caracteristicas do local</TextareaTitle>
                <Textarea
                  onChange={(e) =>
                    handleChange('characteristics', e.target.value)
                  }
                  value={initialValues.characteristics}
                />
              </TextareaContainer>
            </Col>

            <Divider> SERVIÇOS </Divider>

            <Col span={8}>
              <Input
                name='name'
                title='Serviço'
                onChange={handleChangeServices}
                value={servicesValues?.name}
              />
            </Col>
            <Col span={8}>
              <Input
                name='description'
                title='Descrição'
                onChange={handleChangeServices}
                value={servicesValues?.description}
              />
            </Col>
            <Col span={8}>
              <Input
                name='discrimination'
                title='Descriminação'
                onChange={handleChangeServices}
                value={servicesValues?.discrimination}
              />
            </Col>
            <Col span={8}>
              <Input
                name='amount'
                title='Quantidade'
                onChange={handleChangeServices}
                value={servicesValues?.amount}
              />
            </Col>
            <Col span={8}>
              <Input
                name='value'
                title='Valor'
                onChange={handleChangeServices}
                value={servicesValues?.value}
                mask='currency'
              />
            </Col>
            <Col span={8}>
              <Input
                name='total_value'
                title='Total'
                onChange={handleChangeServices}
                value={servicesValues?.total_value}
                mask='currency'
              />
            </Col>

            <Col span={24}>
              <Button
                title='Adicionar serviço'
                buttonClick={handleAddService}
              />
            </Col>

            {initialValues.services_performed.length > 0 && (
              <Col span={24}>
                <Table
                  top={20}
                  title='PRODUTOS OU MÁQUINAS UTILIZADOS PARA EXECUÇÃO'
                  colums={colums}
                  data={initialValues.services_performed || []}
                  // loading={loading}
                />
              </Col>
            )}

            <Divider> INFORMAÇÕES DO CONTRATO </Divider>

            <Col span={8}>
              <Input
                type='date'
                name='start_date'
                title='Data do serviço'
                onChange={handleChange}
                value={initialValues.start_date}
              />
            </Col>

            <Col span={8}>
              <Input
                name='hour'
                title='Horario'
                onChange={handleChange}
                value={initialValues.hour}
                mask='hour'
              />
            </Col>

            <Col span={8}>
              <Input
                name='warrant'
                title='Garantia(Mês)'
                onChange={handleChange}
                value={initialValues.warrant}
                type='number'
              />
            </Col>

            <Col span={8}>
              <Select
                name='budget_technician_id'
                title='Técnico orçamentista'
                value={initialValues.budget_technician_id}
                setValue={handleChange}
                dataOption={comboEmployees}
              />
            </Col>

            <Col span={8}>
              <Select
                name='responsible_technician_id'
                title='Técnico responsavel'
                value={initialValues.responsible_technician_id}
                setValue={handleChange}
                dataOption={comboEmployees}
              />
            </Col>

            <Col span={8}>
              <Select
                name='assistant_technician_id'
                title='Técnico auxiliar'
                value={initialValues.assistant_technician_id}
                setValue={handleChange}
                dataOption={comboEmployees}
              />
            </Col>

            <Col span={8}>
              <Select
                name='status'
                title='Status do contrato'
                value={initialValues.status}
                setValue={handleChange}
                dataOption={selectData.statusContract}
              />
            </Col>

            <Col span={8}>
              <Input
                name='payment'
                title='Forma de pagamento'
                onChange={handleChange}
                value={initialValues.payment}
              />
            </Col>

            <Col span={8}>
              <Input
                name='value'
                title='Valor'
                onChange={handleChange}
                value={initialValues.value}
                mask='currency'
              />
            </Col>

            <Col span={8}>
              <Input
                name='value_amount_paid'
                title='Valor pago'
                onChange={handleChange}
                value={initialValues.value_amount_paid}
                mask='currency'
              />
            </Col>
          </Row>
        </Form>

        {/* {id_contract && (
          <ButtonContainer>
            <Button
              title='Imprimir'
              buttonClick={() =>
                navigate('contrato-pdf', { id_contract: id_contract })
              }
            />
          </ButtonContainer>
        )} */}

        <ButtonContainer>
          <Button title='Voltar' buttonClick={() => navigate(-1)} light />

          <Button
            title={id_contract ? 'Atualizar' : 'Cadastrar'}
            buttonClick={() => handleSubmit()}
          />
        </ButtonContainer>
      </Content>
    </Container>
  );
}
