import React, { useState, useEffect } from 'react'

import {
  Container,
  Content,
  Description,
  Message,
  ProgressBar,
  TypeBar
} from './styles'

import useSnackBar from '../../hooks/snackBar'

export default function SnackBar() {
  const { 
    isOpenSnackBar, 
    setIsOpenSnackBar,
    messageSnackbar,
    setMessageSnackBar,
    descriptionSnackbar,
    setDescriptionSnackBar,
    isSuccess,
    setIsSuccess
  } = useSnackBar()

  const [progress, setProgress] = useState<number>(100)

  useEffect(() => {
    progressTime()
  }, [progress, isOpenSnackBar])

  const progressTime = () => {
    if (progress < 1) {
      setProgress(100)
      setIsOpenSnackBar(false)
      setMessageSnackBar('')
      setDescriptionSnackBar('')
      setIsSuccess(false)
      return
    }

    if (isOpenSnackBar) {
      setTimeout(() => {
        setProgress(progress - 1)
      }, 50)
    }
  }

  return (
    <Container display={isOpenSnackBar ? "flex" : "none"}>
      <TypeBar isSuccess={isSuccess} />

      <Content>
        <Message>{messageSnackbar}</Message>
        <Description>{descriptionSnackbar}</Description>
      </Content>

      <ProgressBar progress={progress} />
    </Container>
  )
}