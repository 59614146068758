import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;
export const Content = styled.div`
  padding: 60px 100px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  column-gap: 50px;

  @media (max-width: 1080px) {
    flex-direction: column;
  }

  @media (max-width: 820px) {
    padding: 60px 50px;
  }

  @media (max-width: 715px) {
    padding: 60px 20px;
  }
`;
export const TableContainer = styled.div`
  width: 100%;

  @media (max-width: 1080px) {
    margin-top: 50px;
    max-width: none;
  }
`;
export const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  align-items: center;
`;
export const DateTitle = styled.span`
  text-align: start;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 19px;
  color: var(--blue_1);
`;

export const ButtonAdd = styled.button`
  /* width: 100%; */
  height: 40px;
  align-items: center;
  display: flex;
  position: relative;
  background-color: var(--background);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  padding: 0 10px;
`;
export const TitleButton = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: var(--blue_1);
  /* margin-left: 25px; */
  margin: 0 10px;
`;
export const LineButton = styled.div`
  width: 10px;
  height: 40px;
  border-radius: 10px 0 0 10px;
  position: absolute;
  left: 0;
  background-color: var(--blue_3);
  top: 0;
`;
export const DataCell = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
