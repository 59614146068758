import API from "../../services/api";

export const GetInventory = async (page?: number) => {
  try {
    const response = await API.get("/inventory", {
      params: {
        page
      }
    })
    return response
  } catch (error) {
    throw error;    
  }
}