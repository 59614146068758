import React, { useCallback } from "react";

import { Spin } from "antd";

import { Container, Title, InputContainer, InputContent } from "./styles";
import {
  cellMask,
  cepMask,
  cpfMask,
  formatCurrency,
  hourMask,
  phoneMask,
  rgMask,
} from "../../helpers/mask";

interface InputProps {
  value?: any;
  name: string;
  type?: string;
  title: string;
  mask?: string;
  loading?: boolean;
  disabled?: boolean;
  onBlur?: (value: any) => void;
  onChange?: (name: string, value: any) => void;
}

export default function Input({
  name,
  mask,
  title,
  value,
  loading,
  disabled,
  onBlur,
  onChange,
  ...props
}: InputProps) {
  const handleChange = (e: any) => {
    if(!onChange) return;
    if (!mask) return onChange(name, e);

    let _value = "";

    switch (mask) {
      case "currency":
        _value = formatCurrency(e);
        break;

      case "cep":
        _value = cepMask(e);
        break;

      case "phone":
        _value = phoneMask(e);
        break;

      case "cellPhone":
        _value = cellMask(e);
        break;

      case "cpf_cnpj":
        _value = cpfMask(e);
        break;

      case "rg":
        _value = rgMask(e);
        break;

      case "hour":
        _value = hourMask(e);
        break;

      default:
        _value = "";
        break;
    }

    return onChange(name, _value);
  };

  const valueInput = useCallback(() => {
    if(mask){
      switch (mask) {
        case "currency":
         value = formatCurrency(value);
          break;
  
        case "cep":
         value = cepMask(value);
          break;
  
        case "phone":
         value = phoneMask(value);
          break;
  
        case "cellPhone":
         value = cellMask(value);
          break;
  
        case "cpf_cnpj":
         value = cpfMask(value);
          break;
  
        case "rg":
         value = rgMask(value);
          break;
  
        case "hour":
         value = hourMask(value);
          break;
  
        default:
         value = value;
          break;
      }
    }
    return value;
  }, [value]);

  return (
    <Container>
      <Title>{title}</Title>

      <InputContainer>
        <InputContent
          onChange={(e) => handleChange(e.target.value)}
          disabled={disabled}
          value={valueInput()}
          onBlur={onBlur}
          {...props}
        />

        {
          loading && (
            <Spin className="spin"/>
          )
        }
      </InputContainer>
    </Container>
  );
}
