import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Login from '../page/login'
import Dashboard from '../page/dashboard'
import Clients from '../page/clients'
import Contract from '../page/contract'
import Employee from '../page/employee'
import Inventory from '../page/inventory'
import CadClients from '../page/cadClients'
import Scheduling from '../page/scheduling'
import CadContract from '../page/cadContract'
import CadEmployee from '../page/cadEmployee'
import PdfContract from '../page/pdfContract'
import CadInventory from '../page/cadInventory'
import FinancialDash from '../page/financialDash'
import FinancialSale from '../page/financialSale'
import CadExpenditure from '../page/cadExpenditure'
import FinancialExpenditure from '../page/financialExpenditure'

export default function RoutesComponent(){
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Dashboard} />
        <Route path="/login" Component={Login} />
        <Route path="/dashboard" Component={Dashboard} />
        <Route path="/clientes" Component={Clients} />
        <Route path="/cadastro/cliente" Component={CadClients} />
        <Route path="/cliente" Component={CadClients} />
        <Route path="/contratos" Component={Contract} />
        <Route path="/cadastro/contrato" Component={CadContract} />
        <Route path="/contrato" Component={CadContract} />
        <Route path="/contrato-pdf" Component={PdfContract} />
        <Route path="/funcionarios" Component={Employee} />
        <Route path="/cadastro/funcionario" Component={CadEmployee} />
        <Route path="/funcionario" Component={CadEmployee} />
        <Route path="/estoque" Component={Inventory} />
        <Route path="/cadastro/estoque" Component={CadInventory} />
        <Route path="/seeInventory" Component={CadInventory} />
        <Route path="/agenda" Component={Scheduling} />
        <Route path="/financeiro" Component={FinancialDash} />
        <Route path="/vendas" Component={FinancialSale} />
        <Route path="/despesas" Component={FinancialExpenditure} />
        <Route path="/cadastro/despesas" Component={CadExpenditure} />
      </Routes>
    </BrowserRouter>
  )
}