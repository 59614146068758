import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import * as yup from 'yup';

import Menu from '../../Components/menu';
import Input from '../../Components/input';
import Button from '../../Components/button';
import Select from '../../Components/select';

import validator from '../../helpers/validator';
import { validatorCpf, validatorCnpj } from '../../helpers/validaterDocument';
import {
  cellMask,
  cepMask,
  cpfMask,
  phoneMask,
  rgMask,
} from '../../helpers/mask';
import useSnackBar from '../../hooks/snackBar';

import { CadClient, GetCEP, ShowClient, UpdateClient } from '../../requests';

import { Container, Title, Form, ButtonContainer } from './styles';

import { Content } from '../../Components/content';

export default function CadClients() {
  const location: any = useLocation();
  const navigate = useNavigate();

  const idClient = location?.state?.id_client;

  const {
    setIsOpenSnackBar,
    setMessageSnackBar,
    setDescriptionSnackBar,
    setIsSuccess,
  } = useSnackBar();

  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    address: '',
    number: '',
    zip_code: '',
    neighborhood: '',
    city: '',
    state: '',
    phone: '',
    cell_phone: '',
    email: '',
    cpf_cnpj: '',
    rg: '',
  });

  const dataSelect = [
    {
      value: '',
      label: 'Selecione',
    },
    {
      value: 'SP',
      label: 'SP',
    },
  ];

  const handleSubmit = async () => {
    setLoading(true);
    const schema = {
      name: yup.string().required('O campo nome é obrigatório'),
      address: yup.string().required('O campo endereço é obrigatório'),
      number: yup.string().required('O campo numero é obrigatório'),
      zip_code: yup.string().required('O campo cep é obrigatório'),
      neighborhood: yup.string().required('O campo bairro é obrigatório'),
      city: yup.string().required('O campo cidade é obrigatório'),
      state: yup.string().required('O campo estado é obrigatório'),
      phone: yup.string().required('O campo telefone é obrigatório'),
      cell_phone: yup.string().required('O campo celular é obrigatório'),
      email: yup.string().email('Informe um email válido'),
      cpf_cnpj: yup
        .string()
        .required('O campo cpf é obrigatório')
        .test(
          'validatorCpf',
          'Informe um cpf/cnpj válido',
          initialValues.cpf_cnpj.length <= 14 ? validatorCpf : validatorCnpj
        ),
      rg: yup.string().nullable(),
    };

    try {
      await validator(initialValues, schema);

      if (idClient) {
        await UpdateClient(idClient, initialValues);
      } else {
        await CadClient(initialValues);
        setInitialValues({
          rg: '',
          name: '',
          city: '',
          state: '',
          phone: '',
          email: '',
          number: '',
          address: '',
          zip_code: '',
          cpf_cnpj: '',
          cell_phone: '',
          neighborhood: '',
        });
      }

      setMessageSnackBar('Cadastro');
      setDescriptionSnackBar('Cadastro realizado com sucesso!');
      setIsOpenSnackBar(true);
      setIsSuccess(true);
    } catch (error: any) {
      setMessageSnackBar('Cadastro');
      setIsOpenSnackBar(true);

      if (error?.response) {
        setDescriptionSnackBar(error?.response?.data?.message);
      } else {
        setDescriptionSnackBar(`${Object.values(error)[0]}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const getCep = async (cep: string) => {
    setLoadingCep(true);
    try {
      const { data } = await GetCEP(cep);

      setInitialValues((prevState) => ({
        ...prevState,
        address: data.logradouro || initialValues.address,
        city: data.localidade || initialValues.city,
        state: data.uf || initialValues.state,
        neighborhood: data.bairro || initialValues.neighborhood,
      }));
    } catch (error) {
    } finally {
      setLoadingCep(false);
    }
  };

  const handleChange = (name: string, value: string) => {
    setInitialValues({ ...initialValues, [name]: value });
  };

  const showClients = async () => {
    try {
      const { data } = await ShowClient(idClient);
      setInitialValues(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (idClient) {
      showClients();
    }
  }, []);

  return (
    <Container>
      <Menu />

      <Content>
        <Title>Cadastro de clientes</Title>

        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                name='name'
                title='Nome'
                onChange={handleChange}
                value={initialValues.name}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                name='zip_code'
                title='CEP'
                onChange={handleChange}
                value={cepMask(initialValues.zip_code)}
                onBlur={() => getCep(initialValues.zip_code)}
                mask='cep'
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name='address'
                title='Endereço'
                loading={loadingCep}
                disabled={loadingCep}
                onChange={handleChange}
                value={initialValues.address}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <Input
                title='Nª'
                name='number'
                onChange={handleChange}
                value={initialValues.number}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                title='Bairro'
                name='neighborhood'
                loading={loadingCep}
                disabled={loadingCep}
                onChange={handleChange}
                value={initialValues.neighborhood}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name='city'
                title='Cidade'
                loading={loadingCep}
                disabled={loadingCep}
                onChange={handleChange}
                value={initialValues.city}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <Select
                name='state'
                title='Estado'
                // loading={loadingCep}
                // disabled={loadingCep}
                setValue={handleChange}
                dataOption={dataSelect}
                value={initialValues.state}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Input
                name='phone'
                title='Fone'
                onChange={handleChange}
                value={phoneMask(initialValues.phone)}
                mask='phone'
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Input
                name='cell_phone'
                title='Celular'
                onChange={handleChange}
                value={cellMask(initialValues.cell_phone)}
                mask='cellPhone'
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name='email'
                title='Email'
                onChange={handleChange}
                value={initialValues.email}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                name='cpf_cnpj'
                title='CPF_CNPJ'
                onChange={handleChange}
                value={cpfMask(initialValues.cpf_cnpj)}
                mask='cpf_cnpj'
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                name='rg'
                title='RG'
                onChange={handleChange}
                value={rgMask(initialValues.rg)}
                mask='rg'
              />
            </Grid>

            <ButtonContainer>
              <Button
                title={idClient ? 'Atualizar' : 'Cadastrar'}
                buttonClick={() => handleSubmit()}
                loading={loading}
              />

              {idClient && (
                <Button
                  title='Contratos'
                  buttonClick={() =>
                    navigate('/contratos', { 
                      state: { id_client: idClient } 
                    })
                  }
                  loading={loading}
                  light
                />
              )}
            </ButtonContainer>
          </Grid>
        </Form>
      </Content>
    </Container>
  );
}
