import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IContractProps } from '../interface';
import { ShowContractPdf } from '../../../requests';
import { formatDate } from '../../../helpers/mask';

export const usePdfContract = () => {
  const componentRef: any = useRef();
  const location: any = useLocation();
  const { id_contract } = location?.state;

  const [dataPdf, setDataPdf] = useState<IContractProps>();

  const getContract = useCallback(async () => {
    const { data } = await ShowContractPdf(id_contract);
    const formatData = {
      ...data,
      start_date: formatDate(data.start_date),
      pest_found: JSON.parse(data.pest_found),
      service_method: JSON.parse(data.service_method),
    };

    setDataPdf(formatData);
  }, [id_contract])

  const findValueArray = useCallback(
    (type: string, value: string) => {
      if (type === 'pestsFound' && dataPdf?.pest_found.length) {
        const findValue = dataPdf?.pest_found.some((item) => item === value);
        return findValue;
      }

      const findValue = dataPdf?.service_method.some((item) => item === value);
      return findValue;
    },
    [dataPdf]
  );

  useEffect(() => {
    if (id_contract) {
      getContract();
    }
  }, []);

  return {
    dataPdf,
    componentRef,
    findValueArray,
  }
}