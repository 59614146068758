import { Container } from "./styles";

import Menu from "../../Components/menu";
import Table from "../../Components/table";
import Button from "../../Components/button";
import { Content } from "../../Components/content";
import ModalFilterContract from '../../Components/modal/filterContract';

import { useContract } from './hooks/useContract';
import { ButtonContainer } from "../cadContract/styles";
import ContractIconDark from "../../assets/contractDark.svg";

export default function Contract() {
  const {
    colums,
    loading,
    navigate,
    idClient,
    contracts,
    filterStatus,
    isOpenModalFilter,
    getContract,
    setFilterStatus,
    handlePagination,
    setIsOpenModalFilter,
  } = useContract();

  return (
    <Container>
      <Menu />

      <Content>
        <Table
          top={50}
          icon={ContractIconDark}
          title="Contratos"
          colums={colums}
          data={contracts?.data || []}
          buttonWithoutIcon={!!idClient}
          loading={loading}
          pagination={contracts?.pagination}
          handlePage={handlePagination}
          titleButtonAdd={
            idClient
              ? "Cadastrar contrato"
              : 'Filtrar contratos'
          }
          renderButton={() =>
            idClient
              ? navigate("/cadastro/contrato", {  state: {id_client: idClient} })
              : setIsOpenModalFilter(true)
          }
        />

        {idClient && (
          <ButtonContainer>
            <Button
              light 
              title="Voltar" 
              buttonClick={() => navigate(-1)} 
            />
          </ButtonContainer>
        )}
      </Content>

      <ModalFilterContract 
        status={filterStatus}
        isOpen={isOpenModalFilter}
        handleSubmit={getContract}
        setStatus={setFilterStatus}
        setIsOpen={setIsOpenModalFilter}
      />
    </Container>
  );
}
