import { useContext } from 'react'

import { SnackBarContext } from '../provider'

const useSnackBar = () => {
  const context = useContext(SnackBarContext)

  if(!context){
    throw new Error('SnackBar error!')
  }

  return context
}

export default useSnackBar