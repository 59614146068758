import styled from "styled-components";
import { StyleContainerProps } from "./interface"

export const Container = styled.div<StyleContainerProps>`
  max-width: 200px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, var(--blue_1), var(--blue_2));
  border-radius: 0 10px 10px 0;
  padding: 25px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  position: fixed;
  z-index: 8;
  overflow: auto;
  align-self: center;

  &::-webkit-scrollbar{
    display: none;
  }

  @media(max-width: 600px) {
    max-width: 600px;
    display: ${({hasOpen}) => hasOpen ? "flex" : "none"};
  };
`
export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 46px;

  img{
    width: 60px;
    margin-bottom: 6px;
  }

  span{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #F7F7F7;
    text-align: center;
    height: 21px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;

    img{
      width: 40px;
    }
  }
`;

export const MenuBurg = styled.button<StyleContainerProps>`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 30px;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: ${({hasOpen}) => hasOpen ? "#FBFFFE" : "#4FA9B8"};
  border: none;
  z-index: 11;
  flex-direction: column;
  transition: box-shadow 1s ease-in-out;
  /* padding: 15px; */
  box-sizing: border-box;
  border-radius: 50%;
  box-shadow: 0 0 0 0 #4FA9B8, 0 0 0 0 #4FA9B8;

  /* ${({hasOpen}) => hasOpen && "box-shadow: 0 0 0 130vw #4FA9B8, 0 0 0 130vh #4FA9B8"}; */

  .hamburguer{
    background-color: #000;
    position: relative;
    width: 20px;
    height: 2px;

    ${({hasOpen}) => hasOpen && "transform: rotate(45deg)"};

  }

  .hamburguer:before,
  .hamburguer:after {
    background-color: #000;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: .5s ease-in-out;
  }

  .hamburguer:before{
    top: -10px;

    ${({hasOpen}) => hasOpen && "transform: rotate(90deg)"};
    ${({hasOpen}) => hasOpen && "top: 0"};
  }

  .hamburguer:after{
    bottom: -10px;

    ${({hasOpen}) => hasOpen && "transform: rotate(90deg)"};
    ${({hasOpen}) => hasOpen && "bottom: 0"};
  }

  @media(max-width: 600px) {
    display: flex;
  }

`;
export const MenuItem = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;  
  margin-bottom: 25px;
  background-color: transparent;
  border: none;
  width: 100%;
  position: relative;

  img{
    margin-bottom: 6px;
  }
  
  span{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #F7F7F7;
  }
`
export const Selected = styled.div`
  position: absolute;
  left: 0;
  height: 45px;
  width: 5px;
  border-radius: 0 10px 10px 0;
  background-color: #F7F7F7;
`
export const ButtonExit = styled.button`
  margin-top: 20px;
  width: 100%;
  max-width: 116px;
  border-radius: 5px;
  min-height: 35px;
  background-color: var(--blue);
  padding: 0 15px 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #f7f7f7;
`