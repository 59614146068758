import React from 'react';
import Routes from './routes';
import { GlobalStyle } from './styles/global';

import { 
  AuthProvider,
  SnackBarProvider,
} from './provider'
import SnackBar from './Components/snackBar';

function App() {
  return (
    <AuthProvider>
      <SnackBarProvider>
        <Routes />
        <GlobalStyle />
        <SnackBar/>
      </SnackBarProvider>
    </AuthProvider>
  );
}

export default App;
