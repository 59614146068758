import API from "../../services/api";

export const GetDashboard = async (month: number, year: number) => {
  try {
    const { data } = await API.get("/dashboard", {
      params: {
        month,
        year,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetDashboardFinancial = async () => {
  try {
    const { data } = await API.get("/dashboard/financial");
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetSixMonthPay = async () => {
  try {
    const { data } = await API.get("/financialPay/last/months");
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetSixMonthSale = async () => {
  try {
    const { data } = await API.get("/contract/last/months");
    return data;
  } catch (error) {
    throw error;
  }
};
