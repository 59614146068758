import styled from "styled-components";

import { IButtonStyleProps } from './interface'

export const Container = styled.button<IButtonStyleProps>`
  width: 235px;
  height: 50px;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  background-color: ${({light}) => light ? 'var(--background)' : 'var(--blue)'} ;
  color: ${({light}) => light ? 'var(--blue)' : 'var(--background)'} ;
  border: 1px solid var(--blue);
`