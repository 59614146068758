import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
`
export const Title = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);
  position: absolute;
  top: -13px;
  left: 10px;
  background-color: var(--background);
  padding: 0 5px;
  box-sizing: border-box;
  z-index: 2;
`
export const InputContainer = styled.div`
  display: flex;
  
  .spin {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`
export const InputContent = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: solid 1px var(--blue_3);
  padding: 0 10px;
`
