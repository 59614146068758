import {createContext, useState} from 'react'

import { 
  LoginEmployee,
  LoginAuthenticated
} from '../requests'

import useSnackBar from "../hooks/snackBar";

interface ILoginProps{
  user: string
  password: string
}

const initialState = {
  signIn: (value: any) => {},
  user: '',
  getUserLogged: () => {}
}

export const AuthContext = createContext(initialState)

export const AuthProvider = ({children}: any) => {
  const { 
    setIsOpenSnackBar, 
    setMessageSnackBar, 
    setDescriptionSnackBar,
    setIsSuccess
  } = useSnackBar()

  const [authData, setAuthData] = useState('')

  const signIn = async ({user, password}: ILoginProps) => {
    
    try {
      const {data} = await LoginEmployee({user, password})
      setAuthData(data)

      sessionStorage.setItem('accessTokenK', data.token)

      setMessageSnackBar('Login')
      setDescriptionSnackBar('Login realizado com sucesso!')
      setIsOpenSnackBar(true)
      setIsSuccess(true)

      return data
    } catch (error: any) {
      throw error
    }
  }

  const getUserLogged = async () => {
    try {
      const { data } = await LoginAuthenticated()
      return data
    } catch (error) {
    }
  }

  return (
    <AuthContext.Provider
      value={{ 
        signIn, 
        user: authData,
        getUserLogged
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}