import React, { useEffect, useMemo, useState } from "react";

import { Tag } from 'antd';
import moment from "moment";
import { Grid } from "@mui/material";

import { Container } from "./styles";

import Menu from "../../Components/menu";
import Table from "../../Components/table";
import Select from "../../Components/select";
import { Content } from "../../Components/content";

import SaleIconDark from "../../assets/saleDark.svg";

import DataMock from "./dataMock.json";
import { GetContract } from "../../requests";
import { DataContractProps } from "../contract/interface";
import { IGetContractProps } from "../../requests/contract/interface";

import { formatCurrency, formatCurrencyToNumber, formatNumberCurrency } from "../../helpers/mask"
import { getYears } from "../../helpers/utils";

export default function FinancialSale() {
  const today = new Date();

  const [filterData, setFilterData] = useState({
    month: today.getMonth() + 1,
    year: today.getFullYear(),
  });
  const [data, setData] = useState<DataContractProps>();

  const statusColor = useMemo(
    () => ({
      paid: {
        color: '#4CAF50',
        label: 'PAGO',
      },
      pending: {
        color: '#FF0000',
        label: 'PENDENTE',
      },
  }),[]);

  const columns = [
    {
      name: "Cliente",
      key: "client",
      sub_key: "name"
    },
    {
      name: "Data",
      render: (item: IGetContractProps) => (
        <span>
          {moment(item?.start_date).format("DD/MM/YYYY")}
        </span>
      )
    },
    {
      name: "Status",
      render: (item: IGetContractProps) => (
        <Tag color={statusColor[item.paid_out].color}>{statusColor[item.paid_out].label}</Tag>
      )
    },
    {
      name: "Valor",
      render: (item: IGetContractProps) => (
        <span>
          {formatNumberCurrency(item.value as number)}
        </span>
      )
    }
  ]

  const getSales = async (page = 1) => {
    try {
      const { data } = await GetContract(page, filterData);
      setData(data)
    } catch (error) {}
  };

  const handlePagination = (page?: number) => {
    getSales(page);
  };

  const handleFilter = (name: string, value: string) => {
    setFilterData({...filterData, [name]: value})
  };
  
  useEffect(() => {
    getSales();
  }, [filterData]);


  return (
    <Container>
      <Menu />

      <Content>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12} sm={12} md={3}>
            <Select
              title="Mês"
              value={filterData.month}
              setValue={handleFilter}
              dataOption={DataMock.month}
              name="month"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Select
              title="Ano"
              value={filterData.year}
              setValue={handleFilter}
              dataOption={getYears()}
              name="year"
            />
          </Grid>
        </Grid>

        <Table
          top={50}
          icon={SaleIconDark}
          title="Vendas"
          colums={columns}
          data={data?.data || []}
          handlePage={handlePagination}
          pagination={data?.pagination}
        />
      </Content>
    </Container>
  );
}
