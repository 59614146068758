export const getMonth = () => {
  return [
    {
      label: "Jan",
      value: 1,
    },
    {
      label: "Fev",
      value: 2,
    },
    {
      label: "Mar",
      value: 3,
    },
    {
      label: "Abr",
      value: 4,
    },
    {
      label: "Maio",
      value: 5,
    },
    {
      label: "Jun",
      value: 6,
    },
    {
      label: "Jul",
      value: 7,
    },
    {
      label: "Ago",
      value: 8,
    },
    {
      label: "Set",
      value: 9,
    },
    {
      label: "Out",
      value: 10,
    },
    {
      label: "Nov",
      value: 11,
    },
    {
      label: "Dez",
      value: 12,
    },
  ];
};

export const getYears = () => {
  const today = new Date();

  const years = [];

  for (let index = 2010; index <= today.getFullYear(); index++) {
    years.push({
      label: index,
      value: index,
    });
  };

  return years;
};

export const isNumeric = (value: any) => {
  if (typeof value === 'number') {
    return true;
  }

  return false;
}