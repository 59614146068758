import { ExpenditureRequest } from "../../dtos/financial";
import API from "../../services/api";

export const GetExpenditure = async (page: number, filter?: any) => {
  try {
    const res = await API.get("/financialPay", {
      params: {
        page,
        ...filter
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export const CadExpenditures = async (data: ExpenditureRequest) => {
  try {
    const res = await API.post("/financialPay", data);
    return res;
  } catch (error) {
    throw error;
  }
}