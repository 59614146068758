import styled from "styled-components";

interface InputContainerProps {
  width: number
}

export const Container = styled.div`
  display: flex;
`
export const Content = styled.div`
  padding: 60px 100px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;

  @media(max-width: 820px) {
    padding: 60px 50px;
  }

  @media(max-width: 715px) {
    padding: 60px 20px;
  }
`
export const Title = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #4E7178;
  width: 100%;
  text-align: center;
  display: block;
`
export const Form = styled.form`
  width: 100%;
  margin-top: 25px;
`
export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;
  max-width: ${({width}) => width}px;
  margin-top: 25px;

  @media(max-width: 1060px) {
    max-width: none;
  }
`
export const RowSelectContainer = styled.div`
  display: flex;
  align-items: flex-end	;
  /* justify-content: center; */
  column-gap: 37.5px;
  row-gap: 20px;
  width: 100%;
  flex-wrap: wrap;
`
export const CardSelected = styled.div`
  height: 40px;
  padding: 0 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;

  @media(max-width: 650px) {
    width: 100%;
    justify-content: space-between;
  }

`
export const LineCard = styled.div`
  width: 10px;
  height: 40px;
  border-radius: 10px 0 0 10px;
  position: absolute;
  left: 0;
  background-color: var(--blue_3);
  top: 0;
`
export const TitleCard = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);
  margin: 0 10px;

  @media(max-width: 650px) {
    text-align: center;
    width: 100%;
  }
`
export const TextareaContainer = styled.div`
  position: relative;
  margin-top: 25px;
  width: 100%;
`
export const TextareaTitle = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);
  position: absolute;
  top: -13px;
  left: 10px;
  background-color: var(--background);
  padding: 0 5px;
  box-sizing: border-box;
`
export const Textarea = styled.textarea`
  width: 100%;
  height: 180px;
  border-radius: 10px;
  border: solid 1px var(--blue_3);
  padding: 15px 10px;
`
export const DeleteCardSelected = styled.button`
  background-color: transparent;
  border: none;
`
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 37.5px;

  @media(max-width: 1060px) {
    flex-direction: column;
  }
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  column-gap: 20px;
`