import { useState, useEffect, useCallback } from "react";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import moment from "moment";

import Grid from "@mui/material/Grid";

import Button from "../../Components/button";
import Input from "../../Components/input";
import Menu from "../../Components/menu";

import { validatorCpf } from "../../helpers/validaterDocument";
import validator from "../../helpers/validator";
import {
  rgMask,
  cepMask,
  cpfMask,
  cellMask,
  phoneMask,
  formatCurrency,
  formatCurrencyToNumber,
} from "../../helpers/mask";
import useSnackBar from "../../hooks/snackBar";

import {
  Form,
  Title,
  Divider,
  Container,
  DividerTitle,
  ButtonContainer,
  DividerContainer,
} from "./styles";

import { Content } from "../../Components/content";

import {
  GetCEP,
  CadEmployees,
  ShowEmployee,
  UpdateEmployees,
  LoginAuthenticated,
} from "../../requests";
import Select from "../../Components/select";

export default function CadEmployee() {
  const location: any = useLocation();
  const idEmployee = location?.state?.id_employee;

  const {
    setIsOpenSnackBar,
    setMessageSnackBar,
    setDescriptionSnackBar,
    setIsSuccess,
  } = useSnackBar();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCep, setLoadingCep] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({
    rg: "",
    cpf: "",
    name: "",
    city: "",
    role: "",
    user: "",
    state: "",
    phone: "",
    email: "",
    number: "",
    sector: "",
    salary: "",
    address: "",
    zip_code: "",
    workload: "",
    password: "",
    cell_phone: "",
    company_id: "",
    occupation: "",
    neighborhood: "",
    admission_date: new Date(),
    resignation_date: undefined,
    companyEmployee: {
      corporate_name: ''
    },
  });

  const typeUsers = [
    {
      value: "",
      label: "Selecione",
    },
    {
      value: "ROLE_ADMIN",
      label: "Admin",
    },
    {
      value: "ROLE_SECRETARY",
      label: "Secretario(a)",
    },
    {
      value: "ROLE_USER",
      label: "Usuário",
    },
  ];

  const showEmployee = async () => {
    setLoading(true);
    try {
      const { data } = await ShowEmployee(idEmployee);

      const formatData = { 
        ...data,
        admission_date: moment(data?.admission_date).format("YYYY-MM-DD"),
        resignation_date: data.resignation_date ? moment(data?.resignation_date).format("YYYY-MM-DD") : null,
      };

      setInitialValues(formatData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getCep = async (cep: string) => {
    setLoadingCep(true);
    try {
      const { data } = await GetCEP(cep);

      setInitialValues((prevState) => ({
        ...prevState,
        state: data.uf,
        city: data.localidade,
        address: data.logradouro,
        neighborhood: data.bairro,
      }));
    } catch (error) {} finally {
      setLoadingCep(false)
    }
  };

  const handleChange = (name: string, value: string) => {
    setInitialValues({ ...initialValues, [name]: value });
  };

  const getEmployeeAuth = useCallback(async () => {
    try {
      const { data } = await LoginAuthenticated();
      setInitialValues(prevState => ({
        ...prevState,
        companyEmployee: {
          corporate_name: data.companyEmployee.corporate_name
        }
      }))
      return data
    } catch (error) {
      
    }
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const schema = {
      name: yup.string().required("O campo nome é obrigatório"),
      address: yup.string().required("O campo endereço é obrigatório"),
      number: yup.string().required("O campo numero é obrigatório"),
      zip_code: yup.string().required("O campo cep é obrigatório"),
      neighborhood: yup.string().required("O campo bairro é obrigatório"),
      city: yup.string().required("O campo cidade é obrigatório"),
      state: yup.string().required("O campo estado é obrigatório"),
      phone: yup.string().required("O campo telefone é obrigatório"),
      cell_phone: yup.string().required("O campo celular é obrigatório"),
      email: yup.string().email("Informe um email válido"),
      cpf: yup
        .string()
        .required("O campo cpf é obrigatório")
        .test("validatorCpf", "Informe um cpf/cnpj válido", validatorCpf),
      rg: yup.string().nullable(),
      // company_id: yup.string().required("O campo nome da empresa é obrigatório"),
      sector: yup.string().required("O campo setor é obrigatório"),
      occupation: yup.string().required("O campo Função é obrigatório"),
      admission_date: yup
        .string()
        .required("O campo data de admissão é obrigatório"),
      resignation_date: yup.string().nullable(),
      salary: yup.string().required("O campo salario é obrigatório"),
      workload: yup.string().required("O campo carga horaria é obrigatório"),
      role: yup.string().required("O campo tipo de usuário é obrigatório"),
      user: yup.string().required("O campo usuário é obrigatório"),
      password: yup.string().when("idEmployee", (_, schema) => {
        return idEmployee
          ? schema.nullable()
          : schema.required("O campo senha é obrigatório");
      }),
    };

    try {
      await validator(initialValues, schema);

      const dataSubmit = {
        ...initialValues,
        salary: formatCurrencyToNumber(initialValues.salary)
      }

      if (idEmployee) {
        await UpdateEmployees(idEmployee, dataSubmit);
      } else {
        dataSubmit.admission_date = new Date(dataSubmit.admission_date);

        await CadEmployees(dataSubmit);
        setInitialValues({
          rg: "",
          cpf: "",
          name: "",
          city: "",
          role: "",
          user: "",
          state: "",
          phone: "",
          email: "",
          sector: "",
          number: "",
          salary: "",
          address: "",
          zip_code: "",
          workload: "",
          password: "",
          cell_phone: "",
          company_id: "",
          occupation: "",
          neighborhood: "",
          admission_date: new Date(),
          resignation_date: undefined,
          companyEmployee: {
            corporate_name: ''
          },
        });
      }

      setMessageSnackBar("Cadastro");
      setDescriptionSnackBar("Cadastro realizado com sucesso!");
      setIsOpenSnackBar(true);
      setIsSuccess(true);
    } catch (error: any) {
      setMessageSnackBar("Cadastro");
      setIsOpenSnackBar(true);

      if (error?.response) {
        setDescriptionSnackBar(error?.response?.data?.message);
      } else {
        setDescriptionSnackBar(`${Object.values(error)[0]}`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idEmployee) {
      showEmployee();
    } else {
      getEmployeeAuth();
    }
  }, []);

  return (
    <Container>
      <Menu />

      <Content>
        <Title>{`${idEmployee ? "Atualizar" : "Cadastrar"}`} funcionario</Title>

        <Form>
          <Grid container spacing={3}>
            <DividerContainer>
              <DividerTitle>Dados Pessoais</DividerTitle>
              <Divider />
            </DividerContainer>

            <Grid item xs={12} sm={12} md={12}>
              <Input
                name="name"
                title="Nome"
                onChange={handleChange}
                value={initialValues.name}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                name="zip_code"
                title="CEP"
                onChange={handleChange}
                value={cepMask(initialValues.zip_code)}
                onBlur={() => getCep(initialValues.zip_code)}
                mask="cep"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name="address"
                title="Endereço"
                loading={loadingCep}
                disabled={loadingCep}
                onChange={handleChange}
                value={initialValues.address}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <Input
                title="Nª"
                name="number"
                onChange={handleChange}
                value={initialValues.number}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                title="Bairro"
                name="neighborhood"
                loading={loadingCep}
                disabled={loadingCep}
                onChange={handleChange}
                value={initialValues.neighborhood}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name="city"
                title="Cidade"
                loading={loadingCep}
                disabled={loadingCep}
                onChange={handleChange}
                value={initialValues.city}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <Input
                name="state"
                title="Estado"
                loading={loadingCep}
                disabled={loadingCep}
                onChange={handleChange}
                value={initialValues.state}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Input
                name="phone"
                title="Fone"
                onChange={handleChange}
                value={phoneMask(initialValues.phone)}
                mask="phone"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Input
                name="cell_phone"
                title="Celular"
                onChange={handleChange}
                value={cellMask(initialValues.cell_phone)}
                mask="cellPhone"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name="email"
                title="Email"
                onChange={handleChange}
                value={initialValues.email}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                name="rg"
                title="RG"
                onChange={handleChange}
                value={rgMask(initialValues.rg)}
                mask="rg"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                name="cpf"
                title="CPF"
                onChange={handleChange}
                value={cpfMask(initialValues.cpf)}
                mask="cpf_cnpj"
              />
            </Grid>

            <DividerContainer>
              <DividerTitle>Dados Empresariais</DividerTitle>
              <Divider />
            </DividerContainer>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                disabled
                name="company"
                title="Nome da empresa"
                value={initialValues.companyEmployee.corporate_name}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name="sector"
                title="Setor"
                onChange={handleChange}
                value={initialValues.sector}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name="occupation"
                title="Função"
                onChange={handleChange}
                value={initialValues.occupation}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Input
                name="admission_date"
                title="Admissão"
                onChange={handleChange}
                value={initialValues.admission_date}
                type="date"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Input
                name="resignation_date"
                title="Demissão"
                onChange={handleChange}
                value={initialValues.resignation_date}
                type="date"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name="workload"
                title="Carga horaria"
                onChange={handleChange}
                value={initialValues.workload}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Input
                name="salary"
                title="Salario"
                onChange={handleChange}
                value={formatCurrency(initialValues.salary)}
                mask="currency"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Select
                name="role"
                title="Tipo de usuário"
                setValue={handleChange}
                dataOption={typeUsers}
                value={initialValues.role}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                name="user"
                title="Usuário"
                onChange={handleChange}
                value={initialValues.user}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Input
                name="password"
                title="Senha"
                onChange={handleChange}
                value={initialValues.password}
                type="password"
              />
            </Grid>

            <ButtonContainer>
              <Button
                loading={loading}
                title={idEmployee ? "Atualizar" : "Cadastrar"}
                buttonClick={() => handleSubmit()}
              />
            </ButtonContainer>
          </Grid>
        </Form>
      </Content>
    </Container>
  );
}
