import {createContext, useState } from "react";

const initialState = {
  isOpenSnackBar: false,
  setIsOpenSnackBar: (value: boolean) => {},
  messageSnackbar: '',
  setMessageSnackBar: (value: string) => {},
  descriptionSnackbar: '',
  setDescriptionSnackBar: (value: string) => {},
  isSuccess: false,
  setIsSuccess: (value: boolean) => {}
}

export const SnackBarContext = createContext(initialState)

export const SnackBarProvider = ({children}: any) => {
  const [isOpenSnackBar, setIsOpenSnackBar] = useState<boolean>(false)
  const [messageSnackbar, setMessageSnackBar] = useState<string>('')
  const [descriptionSnackbar, setDescriptionSnackBar] = useState<string>('')
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  return (
    <SnackBarContext.Provider
      value={{
        isOpenSnackBar,
        setIsOpenSnackBar,
        messageSnackbar,
        setMessageSnackBar,
        descriptionSnackbar,
        setDescriptionSnackBar,
        isSuccess,
        setIsSuccess
      }}
    >
      {children}
    </SnackBarContext.Provider>
  )
}