import styled from "styled-components";

interface LabelProps{
  value: string
}

interface ContentProps{
  hasError?: boolean
}

export const Container = styled.div`
  width: 100%;
  position: relative;

  img{
    position: absolute;
    right: 10px;
    top: 15px;
  }
`
export const Content = styled.select<ContentProps>`
  width: 100%;
  /* max-width: 115px; */
  height: 40px;
  border-radius: 10px;
  position: relative;
  border: solid 1px var(--blue_3);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  cursor: pointer;
  padding: 0 15px;
`

export const Title = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);
  position: absolute;
  top: -13px;
  left: 10px;
  background-color: #fff;
  padding: 0 5px;
  box-sizing: border-box;
`
export const Label = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: var(--blue_1);
  /* position: absolute;
  top: -11px;
  left: 10px; */
  background-color: #fff;
  padding: 0 5px;
  box-sizing: border-box;
`
export const BoxData = styled.div`
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  /* max-width: 115px; */
  border-radius: 0 0 10px 10px;
  border: solid 1px var(--blue_3);
  top: 39px;
  z-index: 9;
`
export const BoxValue = styled.button`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);
  /* margin-bottom: 5px; */
  border-bottom: solid 1px var(--blue_3);
  border-top: none;
  border-right: none;
  border-left: none;
  text-align: center;
  padding: 10px 10px 10px 10px;
  background-color: transparent;
  transition: all 0.5s;
  /* border: none; */
  &:last-child{
    border-bottom: none;
    padding-bottom: 0;
  }

  &:first-child{
    padding-top: 0;
  }

  &:hover{
    font-weight: 400;
  }
`