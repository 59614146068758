import { useState, useEffect } from "react";

import {
  Container,
  Content,
  Title,
  Label,
  BoxData,
  BoxValue
} from './styles'

import { SelectProps} from "./interface"

import ArrowIcon from '../../assets/arrowSelect.svg'


export default function Select({
  value,
  title,
  disabled,
  error,
  dataOption,
  setValue,
  name
}: SelectProps) {

  useEffect(() => {
  }, [value])

  return (
    <Container>
      <Content
        disabled={disabled}
        value={value}
        hasError={error}
        onChange={e => setValue(name, e.target.value)}
      >
        {
          dataOption && dataOption.map((item, index) => (
            <option key={item.value || index + ""} value={item.value}>{item.label}</option>
          ))
        }
      </Content>
      {
        title && (
          <Title>{title}</Title>
        )
      }
      <img src={ArrowIcon} alt='setas' />
    </Container>
  )
}