import styled from "styled-components";

export const Content = styled.div`
  padding: 60px 50px 60px 250px;
  box-sizing: border-box;
  width: 100%;

  @media(max-width: 600px) {
    padding: 60px 20px 60px 20px;
  }
`