import * as yup from 'yup';

function validator(body, schema) {
  return new Promise(async function(resolve, reject) {
    
    const _schema = yup.object().shape(schema);

    try {
      await _schema.validate(body, {
        abortEarly: false,
      });
      
      return resolve();
    } catch (error) {
      const validationErrors = {};
      
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
      }

      return reject(validationErrors);
    }
  });
};

export default validator;