import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root{
    --background: #FBFFFE;
    --red: #e52e4d;
    --green: #33cc95;
    --blue: #202B30;
    --blue_1: #4E7178;
    --blue_2: #4FA9B8;
    --blue_3: #74C0CF;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    @media (max-width: 1080) {
      font-size: 93.75%;
    }

    @media (max-width: 1080) {
      font-size: 87.5%;
    }
  }

  body{
    background-color: var(--background);
    -webkit-font-smooth: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 600;
  }

  button{
    cursor: pointer;

    :disabled{
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  input, textarea{
    outline: 0;
  }

  .react-modal-overlay{
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
  }

  .react-modal-content{
    width: 100%;
    max-width: 576px;
    background-color: var(--background);
    padding: 3rem;
    position: relative;
    border-radius: 0.25rem;
  }

  .react-modal-close{
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background-color: transparent;
    transition: filter 0.2s;

    &:hover{
      filter: brightness(0.8);
    }
  }
`