import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
export const Select = styled.div`
  width: 100%;
  max-width: 115px;
  height: 40px;
`
export const SelectContainer = styled.div`
  max-width: 130px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
`
export const InputContainer = styled.div`
  width: 100%;
  margin-left: 15px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
`