import {
  Container,
  Title,
  TableContainer,
  TableBody,
  TableHeader,
  PaginationContainer,
  PaginationButton,
  PaginationText,
  Content,
  Header,
  ButtonAdd,
  LineButton,
  TitleButton,
} from "./styles";

import PaginationAllLeftIcon from "../../assets/paginationAllLeft.svg";
import PaginationLeftIcon from "../../assets/paginationLeft.svg";
import PaginationAllRightIcon from "../../assets/paginationAllRight.svg";
import PaginationRightIcon from "../../assets/paginationRight.svg";
import PlusIcon from "../../assets/plus.svg";
import { LoadingLight } from "../preloaders";
import { PaginationProps } from "./interface";

interface TableProps {
  top: number;
  icon?: string;
  title: string;
  data: Array<any>;
  loading?: boolean;
  colums: Array<any>;
  filterDate?: boolean;
  titleButtonAdd?: string;
  buttonWithoutIcon?: boolean;
  pagination?: PaginationProps;
  renderButton?: () => any;
  handlePage?: (value: number) => any;
}

export default function Table({
  top,
  icon,
  title,
  colums,
  loading,
  data = [],
  pagination,
  titleButtonAdd,
  buttonWithoutIcon,
  handlePage,
  renderButton,
}: TableProps) {
  const handleNextPage = (all?: string) => {
    if (!pagination) return;

    const currentPage = Number(pagination.current_page);
    const totalPages = Number(pagination.total_pages);

    if (currentPage === totalPages) return;
    if (all && handlePage) handlePage(totalPages);

    const nextPage = currentPage + 1;

    handlePage && handlePage(nextPage);
  };

  const handleBackPage = (all?: string) => {
    if (!pagination) return;
    const currentPage = Number(pagination.current_page);
    if (currentPage < 2) return;

    if (all && handlePage) handlePage(1);

    const nextPage = currentPage - 1;

    handlePage && handlePage(nextPage);
  };

  return (
    <Container top={top}>
      <Header>
        <Title>
          {icon && <img src={icon} alt="Icon" />}
          {title}
        </Title>

        {titleButtonAdd && renderButton && (
          <ButtonAdd onClick={() => renderButton()}>
            <LineButton />
            {loading ? (
              <LoadingLight />
            ) : (
              <>
                <TitleButton>{titleButtonAdd}</TitleButton>
                {buttonWithoutIcon && <img src={PlusIcon} alt="Plus" />}
              </>
            )}
          </ButtonAdd>
        )}
      </Header>

      <Content>
        <TableContainer>
          <thead>
            <TableHeader>
              {colums?.map((colum, i) => (
                <th key={i}>{colum?.name}</th>
              ))}
            </TableHeader>
          </thead>
          {loading ? (
            <LoadingLight />
          ) : (
            <>
              <tbody>
                {data.length > 0 &&
                  data?.map((_data, index) => (
                    <TableBody key={index}>
                      {colums?.map((colum, i) => (
                        <td key={i}>
                          {colum?.render
                            ? colum?.render(data[index], index)
                            : colum?.sub_key
                            ? data[index][colum?.key][colum?.sub_key]
                            : data[index][colum?.key]}
                        </td>
                      ))}
                    </TableBody>
                  ))}
              </tbody>
            </>
          )}
        </TableContainer>
      </Content>

      {pagination && pagination.total > 0 && (
        <PaginationContainer>
          <PaginationButton onClick={() => handleBackPage("all")}>
            <img src={PaginationAllLeftIcon} alt="arrow" />
          </PaginationButton>
          <PaginationButton onClick={() => handleBackPage()}>
            <img src={PaginationLeftIcon} alt="arrow" />
          </PaginationButton>

          <PaginationText>
            {Number(pagination.current_page)} - {pagination.total_pages}
          </PaginationText>

          <PaginationButton onClick={() => handleNextPage()}>
            <img src={PaginationRightIcon} alt="arrow" />
          </PaginationButton>
          <PaginationButton onClick={() => handleNextPage("all")}>
            <img src={PaginationAllRightIcon} alt="arrow" />
          </PaginationButton>
        </PaginationContainer>
      )}
    </Container>
  );
}
