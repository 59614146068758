import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`
export const Content = styled.div`
  padding: 60px 100px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;

  @media(max-width: 820px) {
    padding: 60px 50px;
  }

  @media(max-width: 715px) {
    padding: 60px 20px;
  }
`

export const PDFContainer = styled.div`
  max-width: 800px;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  min-height: 1120px;
`;

export const TextLine = styled.span`
  text-decoration: solid;
  border-bottom: 1px solid #000;
  margin-left: 5px;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #4E7178;
  width: 100%;
  text-align: center;
  display: block;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  column-gap: 20px;
`

export const TextAuthorization = styled.span`
  display: block;
  font-size: 10px;
  margin: 16px 0;
`;

export const ServicesPerformed = styled.div`
  margin-bottom: 16px;
`;

export const ServicesPerformedTitle = styled.strong`
  margin-bottom: 10px;
`;

export const ContainerInfos = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 800px;
  opacity: 0.1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1120px;
  z-index: 0;
`;

export const Image = styled.img`
  width: 800px;
`;

export const Fieldset = styled.div`
  border: 1px solid #333;
  border-radius: 5px;
  padding: 5px;
  background-color: #FFFFFF;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #333;

  thead {
    tr {
      border-bottom: 1px solid #333;
    }
    
    th {
      padding: 5px;
      text-align: start;
      border-right: 1px solid #333;
    };
  }

  tbody {
    tr {
      border-bottom: 1px solid #333;
    }
    td {
      padding: 5px;
      text-align: start;
      border-right: 1px solid #333;
    }
  }
`;