import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Menu from '../../Components/menu';

import { Container } from './styles';

import Table from '../../Components/table';
import Search from '../../Components/search';
import ButtonIcon from '../../Components/buttonIcon';
import { Content } from '../../Components/content';

import ClientsIconDark from '../../assets/clientsDark.svg';
import EyeIcon from '../../assets/eye.svg';

import { GetClient } from '../../requests';
import { DataClientProps } from './interface';
import { ICadClientProps } from '../../requests/client/interface';
import { cpfMask } from '../../helpers/mask';

export default function Clients() {
  const navigate = useNavigate();

  const [clients, setClients] = useState<DataClientProps>();
  const [inputData, setInputData] = useState<string>('');
  const [initialValues, setInitialValues] = useState({
    search: '',
  });

  const colums = [
    {
      name: 'Nome',
      key: 'name',
    },
    {
      name: 'Endereço',
      key: 'address',
    },
    {
      name: 'Numero',
      key: 'number',
    },
    {
      name: 'CPF/CNPJ',
      key: 'cpf_cnpj',
      render: (data: ICadClientProps) => <span>{cpfMask(data.cpf_cnpj)}</span>,
    },
    {
      name: 'Ver',
      render: (data: ICadClientProps) => (
        <ButtonIcon onClick={() => (data.id ? handleSee(data.id) : {})}>
          <img src={EyeIcon} />
        </ButtonIcon>
      ),
    },
  ];

  useEffect(() => {
    getClients();
  }, [inputData]);

  const getClients = async (page?: number) => {
    try {
      const { data }: any = await GetClient(
        initialValues.search,
        inputData,
        page
      );
      setClients(data);
    } catch (error) {}
  };

  const handleSee = (id: string) => {
    navigate('/cliente', { state: { id_client: id } });
  };

  const handleChange = (name: string, value: string) => {
    setInitialValues({ ...initialValues, [name]: value });
  };

  const handlePagination = (page?: number) => {
    getClients(page);
  };

  return (
    <Container>
      <Menu />

      <Content>
        <Search
          value={initialValues.search}
          setValue={handleChange}
          inputData={inputData}
          setInputData={setInputData}
          name='search'
        />

        <Table
          top={50}
          icon={ClientsIconDark}
          title='Clientes'
          colums={colums}
          data={clients?.data || []}
          titleButtonAdd='Novo cliente'
          renderButton={() => navigate('/cadastro/cliente')}
          pagination={clients?.pagination}
          handlePage={handlePagination}
        />
      </Content>
    </Container>
  );
}
