import React, { useState, useEffect, useMemo } from "react";

import { Tag } from 'antd';
import { Grid } from "@mui/material";

import Menu from "../../Components/menu";
import Table from "../../Components/table";
import Select from "../../Components/select";
import { Content } from "../../Components/content";
import ButtonIcon from "../../Components/buttonIcon";

import { Container } from "./styles";

import EyeIcon from "../../assets/eye.svg";
import ClientsIconDark from "../../assets/clientsDark.svg";

import { formatDate } from "../../helpers/mask";
import { getMonth, getYears } from "../../helpers/utils";
import { GetExpenditure } from "../../requests/financial";

import { DataAPI } from "../../dtos/getApi";
import { ExpenditureResponse } from "../../dtos/financial";
import { useNavigate } from "react-router-dom";

export default function FinancialExpenditure() {
  const today = new Date();
  const navigate = useNavigate();

  const statusColor = useMemo(
    () => ({
      paid: {
        color: '#4CAF50',
        label: 'PAGO',
      },
      pending: {
        color: '#FF0000',
        label: 'PENDENTE',
      },
  }),[]);

  const colums = [
    {
      name: "Categoria",
      key: "category",
    },
    {
      name: "Data de vencimento",
      render: (item: ExpenditureResponse) => (
        <span>
          {formatDate(item.due_date)}
        </span>
      )
    },
    {
      name: "Parcela",
      key: "installments",
    },
    {
      name: "Situação",
      render: (item: ExpenditureResponse) => (
        <Tag color={statusColor[item.situation].color}>{statusColor[item.situation].label}</Tag>
      )
    },
    {
      name: "Ver",
      render: (data: ExpenditureResponse) => (
        <ButtonIcon onClick={() => handleSee(data.id)}>
          <img src={EyeIcon} />
        </ButtonIcon>
      ),
    },
  ];

  const [filterData, setFilterData] = useState({
    month: today.getMonth() + 1,
    year: today.getFullYear(),
  });
  const [data, setData] = useState<DataAPI<ExpenditureResponse[]>>();

  const handleFilter = (name: string, value: string) => {
    setFilterData({...filterData, [name]: value})
  };

  const getExpenditure = async (page = 1) => {
    try {
      const { data } = await GetExpenditure(page, filterData);
      setData(data)
    } catch (error) {}
  };

  const handlePagination = (page?: number) => {
    getExpenditure(page);
  };

  const handleSee = (id: string) => {
    // history.push("contrato", { id_contract: id });
  };

  useEffect(() => {
    getExpenditure();
  }, [filterData]);

  return (
    <Container>
      <Menu />

      <Content>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12} sm={12} md={3}>
            <Select
              title="Mês"
              value={filterData.month}
              setValue={handleFilter}
              dataOption={getMonth()}
              name="month"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Select
              title="Ano"
              value={filterData.year}
              setValue={handleFilter}
              dataOption={getYears()}
              name="year"
            />
          </Grid>
        </Grid>

        <Table
          top={50}
          colums={colums}
          title="Despesas"
          icon={ClientsIconDark}
          data={data?.data || []}
          titleButtonAdd="Nova despesa"
          handlePage={handlePagination}
          pagination={data?.pagination}
          renderButton={() => navigate("/cadastro/despesas")}
        />
      </Content>
    </Container>
  );
}
