import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAuth from "../../hooks/auth";

import {
  Container,
  UserContainer,
  MenuItem,
  Selected,
  ButtonExit,
  MenuBurg
} from './styles'

import { IUserProps } from './interface'

import UserIcon from '../../assets/user.svg'
import DashIcon from '../../assets/dash.svg'
import ClientIcon from '../../assets/clients.svg'
import ContractIcon from '../../assets/contract.svg'
import SchedulingIcon from '../../assets/scheduling.svg'
import InventoryIcon from '../../assets/inventory.svg'
import FinancialIcon from '../../assets/financial.svg'
import ExitIcon from '../../assets/exit.svg'
import EmployeeIcon from '../../assets/employee.svg'

export default function Menu() {
  const navigate = useNavigate()

  const { getUserLogged } = useAuth()

  const [selected, setSelected] = useState<string>('')
  const [hasOpen, setHasOpen] = useState<boolean>(false)
  const [user, setUser] = useState<IUserProps>({
    name: '',
    role: ''
  })

  useEffect(() => {
    const token = sessionStorage.getItem("accessTokenK")
    if (!token) {
      setTimeout(() => {
        getUser()
      }, 1500)
    } else {
      getUser()
    }
  }, [selected])

  const getUser = async () => {
    const response: any = await getUserLogged()
    setUser(response)
  }

  const handleMenu = (name: string) => {
    setSelected(name)
    navigate(`/${name}`)
  }

  const handleLogout = () => {
    navigate('/login')
  }

  return (
    <>
      <MenuBurg 
        hasOpen={hasOpen}
        onClick={() => setHasOpen(!hasOpen)}
        className={`${hasOpen ? "open" : ""}`}
      >
        <div className="hamburguer" />
      </MenuBurg>

      <Container hasOpen={hasOpen}>

        <UserContainer>
          <img src={UserIcon} alt="Usuário" />
          <span>{user?.name}</span>
        </UserContainer>

        <MenuItem
          onClick={() => handleMenu('dashboard')}
        >
          <img src={DashIcon} alt="Menu" />
          <span>Dashboard</span>
          {
            selected === 'dashboard' && (
              <Selected />
            )
          }
        </MenuItem>

        <MenuItem
          onClick={() => handleMenu('clientes')}
        >
          <img src={ClientIcon} alt="Menu" />
          <span>Clientes</span>
          {
            selected === 'clientes' && (
              <Selected />
            )
          }
        </MenuItem>

        <MenuItem
          onClick={() => handleMenu('contratos')}
        >
          <img src={ContractIcon} alt="Menu" />
          <span>Contratos</span>
          {
            selected === 'contract' && (
              <Selected />
            )
          }
        </MenuItem>

        <MenuItem
          onClick={() => handleMenu('funcionarios')}
          disabled={user?.role !== "ROLE_ADMIN"}
        >
          <img src={EmployeeIcon} alt="Menu" />
          <span>Funcionarios</span>
          {
            selected === 'employee' && (
              <Selected />
            )
          }
        </MenuItem>

        <MenuItem
          // disabled
          onClick={() => handleMenu('agenda')}
        >
          <img src={SchedulingIcon} alt="Menu" />
          <span>Agenda</span>
          {
            selected === 'agenda' && (
              <Selected />
            )
          }
        </MenuItem>

        <MenuItem
          disabled
          onClick={() => handleMenu('estoque')}
        >
          <img src={InventoryIcon} alt="Menu" />
          <span>Estoque</span>
          {
            selected === 'estoque' && (
              <Selected />
            )
          }
        </MenuItem>


        <MenuItem
          onClick={() => handleMenu('financeiro')}
          disabled={user?.role !== "ROLE_ADMIN"}
        >
          <img src={FinancialIcon} alt="Menu" />
          <span>Financeiro</span>
          {
            selected === 'financeiro' && (
              <Selected />
            )
          }
        </MenuItem>

        <ButtonExit onClick={() => handleLogout()}>
          <span>Sair</span>  <img src={ExitIcon} alt="Sair" />
        </ButtonExit>
      </Container>
    </>
  )
}