import styled from "styled-components";

interface ContainerProps {
  top: number
}

export const Container = styled.div<ContainerProps>`
  margin-top: ${({top}) => top}px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
;
`
export const Content = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const Title = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);

  img{
    margin-right: 11px;
  }
`
export const ButtonAdd = styled.button`
  /* width: 100%; */
  height: 40px;
  align-items: center;
  display: flex;
  position: relative;
  background-color: var(--background);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  padding: 0 10px;
`
export const TitleButton = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--blue_1);
  /* margin-left: 25px; */
  margin: 0 10px;
`
export const LineButton = styled.div`
  width: 10px;
  height: 40px;
  border-radius: 10px 0 0 10px;
  position: absolute;
  left: 0;
  background-color: var(--blue_3);
  top: 0;
`
export const TableContainer = styled.table`
  border-collapse: collapse;
  margin-top: 10px;
  min-width: 750px;
  width: 100%;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  tbody{
    background-color: #fff;
  }
`
export const TableHeader = styled.tr`
  width: 100%;
  background-color: var(--blue_3);

  th{
    text-align: start;
    padding: 16px 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--background);

    &:first-child{
      border-top-left-radius:10px;
    }
    &:last-child{
      border-top-right-radius:10px;
    }
  }

`
export const TableBody = styled.tr`
  padding-bottom: 18px;
  border-bottom: 1px solid var(--blue_3);

  td{
    text-align: start;
    padding: 16px 50px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: var(--blue_1);
  }

  &:last-child{
      border-bottom:none;

    }

` 
export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px;
`
export const PaginationButton = styled.button`
  border: none;
  background-color: transparent;
  margin: 0 8px;
`
export const PaginationText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--blue_1);
  margin: 0 9px;
`
export const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 250px;
  column-gap: 20px;
`
export const SelectContent = styled.div`
  max-width: 115px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
`