import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`
export const Content = styled.div`
  padding: 60px 100px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;

  @media(max-width: 820px) {
    padding: 60px 50px;
  }

  @media(max-width: 715px) {
    padding: 60px 20px;
  }
`
export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 970px) {
    flex-direction: column;
    row-gap: 20px;
  }
`